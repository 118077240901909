import { Typography } from "@mui/material";
import VerticalTabs from "../../../pages/ctr/VerticalTabs";
import { useVertical } from "../../../hooks/useVertical";
import { useFavorite } from "../../../hooks/useFavorite";

export default function CustomerService() {
  const label = "고객센터";
  const { verticalDatas } = useVertical();
  const { IconBtn } = useFavorite(label);

  return (
    <div id="customerService">
      <Typography
        variant="h5"
        style={{
          marginBottom: 8,
          fontWeight: 600,
        }}
      >
        {label}
        <IconBtn />
      </Typography>
      <VerticalTabs idx={verticalDatas.orfeo_customerService} />
    </div>
  );
}
