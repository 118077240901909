import { useMemo, useEffect } from "react";
import {
  useExportStore,
  useModalStore,
  useOptionsStore,
} from "../../../../../../store/creates";
import MuiTable from "../../../../../common/MuiTable";
import mergeIdMap from "../../../../../../utils/mapping";
import { useDeleteFloor } from "../../../../../../query/queries/orfeo/venueManage";

export default function GetFloor({ data }) {
  const endpoint = "floor-category";
  const uniKey = "floorCategoryId";
  const { openModal } = useModalStore();
  const { mutateAsync } = useDeleteFloor();
  const originData = useMemo(() => mergeIdMap(uniKey, data || []), [data]);
  const { setOptions, options } = useOptionsStore();
  const { setExport } = useExportStore();

  const deleteTheater = (id) => {
    mutateAsync(id);
  };

  useEffect(() => {
    if (options[uniKey]) return;
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "EXPORT",
        action: (id, data) => {
          const EXPORT = {};
          EXPORT[endpoint] = { id, data };
          setExport(EXPORT);
        },
        tooltip:
          "상영관 층계 등록 및 수정 > 수정 탭에서 IMPORT로 내보낼 수 있습니다.",
      },

      {
        name: "삭제",
        action: (id) => {
          deleteTheater(id);
        },
        tooltip: "해당 데이터를 제거합니다.",
      },
    ];
    setOptions(OPTIONS);
  }, []);

  return (
    <div id="getTheater">
      {originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey}
          headers={Object.keys(originData[0])}
          queryKey={endpoint} //api endpoint
        />
      )}
    </div>
  );
}
