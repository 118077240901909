import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateClaimLogs } from "../../apis/olea/UPDATE";
import { onErrorBind } from "../common";
import { getClaimLog } from "../../apis/olea/GET";

export const useGetClaimLog = (id = "") => {
  return useQuery({
    queryKey: ["get", `claimLog/${id}`],
    queryFn: async () => {
      const { data } = await getClaimLog(id);
      return data;
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
};

export function useUpdateClaimLog() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateClaimLogs(req), {
    onSuccess: async (data, { id }) => {
      const { data: claimData } = await getClaimLog();
      queryClient.setQueryData(["get", "claimLog/"], claimData);
      const { data: claimDetail } = await getClaimLog(id);
      queryClient.setQueryData(["get", `claimLog/${id}`], claimDetail);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}
