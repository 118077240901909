import axios from "axios";
import { getAccessToken } from "./token";

export default class testService {
  static async custom(sendData) {
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    if (sendData.formData) {
      headers["Content-Type"] = "multipart/form-data";
    }
    const { data } = await axios({
      method: sendData.method,
      url: sendData.url,
      data: sendData.payload,
      headers: headers,
    });
    return data;
  }
}
