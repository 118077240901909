import { useHorizontal } from "../../../../../hooks/useHorizontal";
import HorizontalTabs from "../../../../../pages/ctr/HorizontalTabs";

export default function UserTypeChange() {
  const { horizontalDatas } = useHorizontal();

  //사용자 타입 변경
  return (
    <HorizontalTabs idx={horizontalDatas.olea_userManage_userTypeChange} />
  );
}
