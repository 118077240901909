import { Button, Paper, TextField } from "@mui/material";
import {
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useCallback, useMemo } from "react";
import {
  useCreateProductRound,
  useGetProducts,
} from "../../../../../query/queries/orfeo/productsManage";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import MenuItem from "@mui/material/MenuItem";
import CodeSample from "../../../../../utils/CodeSample";
import dayjs from "dayjs";
import MuiDateTimeField from "../../../../common/MuiDateTimeField";

const initValues = {
  productId: "",
  name: "",
  screenDatetime: dayjs().toISOString(),
};

export default function PostProductRound() {
  const { openModal } = useModalStore();
  const { data: productsData } = useGetProducts();
  const { mutateAsync } = useCreateProductRound();
  const { setIsGuard } = useLeavingGuardStore();

  const fields = useMemo(
    () => [
      {
        name: "productId",
        type: "text",
        select: true,
        options: productsData || [],
        required: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "회차 제목을 입력해 주세요.",
        required: true,
      },
      {
        name: "screenDatetime",
        type: "text",
        placeholder: "회차 시간을 입력해 주세요.",
        required: true,
        dateTime: true,
      },
    ],
    [productsData]
  );

  const addProductRound = useCallback((values) => {
    const payload = { ...values };
    // console.log(payload);
    mutateAsync(payload);
  }, []);

  return (
    <div id="postProductRound">
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={() => Schema.productRound()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          openModal(`공연 회차 정보를 등록 하시겠습니까?`, () =>
            addProductRound(values)
          );
        }}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form onChange={() => setIsGuard(true)}>
            <CodeSample sample={values} />
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  {f.dateTime ? (
                    <MuiDateTimeField
                      fieldValue={values.screenDatetime}
                      name={"screenDatetime"}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <Field
                      name={f.name}
                      type={f.type}
                      className="formik-input"
                      id={
                        errors[f.name] &&
                        touched[f.name] &&
                        "error-input-border"
                      }
                    >
                      {({ field, meta }) => (
                        <>
                          <TextField
                            {...field}
                            required={f.required}
                            type={f.type}
                            select={f.select}
                            sx={{ mb: 1.6, minWidth: 150 }}
                            label={f.name}
                            placeholder={f.placeholder}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched && meta.error
                            }
                          >
                            {f.options?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                [공연 ID: {option.id}] {option.title}
                              </MenuItem>
                            ))}
                          </TextField>
                        </>
                      )}
                    </Field>
                  )}
                </div>
              ))}
            </Paper>

            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              공연 회차 정보 등록
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
