import { Button, Paper, TextField } from "@mui/material";
import {
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useCallback } from "react";
import { useCreateVenue } from "../../../../../query/queries/orfeo/venueManage";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import CodeSample from "../../../../../utils/CodeSample";

const fields = [
  {
    name: "name",
    type: "text",
    placeholder: "극장 이름을 입력해 주세요.",
    required: true,
  },
  {
    name: "location",
    type: "text",
    placeholder: "극장 위치를 입력해 주세요.",
    required: true,
  },
  {
    name: "address",
    type: "text",
    placeholder: "극장 주소를 입력해 주세요.",
    required: true,
  },
];

const initValues = {
  name: "",
  location: "",
  address: "",
};
export default function PostVenue() {
  const { openModal } = useModalStore();
  const { mutateAsync } = useCreateVenue();
  const { setIsGuard } = useLeavingGuardStore();

  const addVenue = useCallback((values) => {
    const payload = { ...values };
    mutateAsync(payload);
  }, []);

  return (
    <div id="postVenue">
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={() => Schema.venue()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          openModal(`극장을 추가 하시겠습니까?`, () => addVenue(values));
        }}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form onChange={() => setIsGuard(true)}>
            <CodeSample sample={values} />
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  <Field
                    name={f.name}
                    type={f.type}
                    className="formik-input"
                    id={
                      errors[f.name] && touched[f.name] && "error-input-border"
                    }
                  >
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        required={f.required}
                        type={f.type}
                        select={f.select}
                        sx={{ mb: 1.6, minWidth: 150 }}
                        label={f.name}
                        placeholder={f.placeholder}
                        InputLabelProps={{ shrink: true }}
                        error={meta.error && meta.touched}
                        helperText={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </div>
              ))}
            </Paper>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              극장 등록
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
