import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createAnnouncements,
  createEvents,
  createFaq,
} from "../../apis/orfeo/POST";
import { onErrorBind } from "../common";
import { getAnnouncements, getEvents, getFaq } from "../../apis/orfeo/GET";
import {
  updateAnnouncements,
  updateEvents,
  updateFaq,
} from "../../apis/orfeo/UPDATE";
import {
  deleteAnnouncements,
  deleteEvents,
  deleteFaq,
} from "../../apis/orfeo/DELETE";

//공지사항
export function useCreateAnnouncements() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createAnnouncements(payload), {
    onSuccess: async () => {
      queryClient.invalidateQueries(["get", "announcements/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetAnnouncements() {
  return useQuery({
    queryKey: ["get", `announcements/`],
    queryFn: async () => {
      const { data } = await getAnnouncements();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateAnnouncements() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateAnnouncements(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "announcements/"]);
      queryClient.invalidateQueries(["get", `announcements/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteAnnouncements() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteAnnouncements(id), {
    onSuccess: async () => {
      queryClient.invalidateQueries(["get", "announcements/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

//이벤트
export function useCreateEvents() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createEvents(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "events/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetEvents() {
  return useQuery({
    queryKey: ["get", `events/`],
    queryFn: async () => {
      const { data } = await getEvents();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateEvents() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateEvents(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "events/"]);
      queryClient.invalidateQueries(["get", `events/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteEvents() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteEvents(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "events/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

//faq
export function useCreateFaq() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createFaq(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "faq/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetFaq() {
  return useQuery({
    queryKey: ["get", `faq/`],
    queryFn: async () => {
      const { data } = await getFaq();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateFaq() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateFaq(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "faq/"]);
      queryClient.invalidateQueries(["get", `faq/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteFaq() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteFaq(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "faq/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}
