import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Paper, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useSignIn } from "../../../../query/queries/orfeo/users";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../utils/validate/Schema";
import { useTokenStore } from "../../../../store/creates";

const fields = [
  {
    name: "userId",
    type: "text",
    placeholder: "아이디를 입력해 주세요.",
    required: true,
  },
  {
    name: "password",
    type: "password",
    placeholder: "패스워드를 입력해 주세요.",
    required: true,
  },
];

export default function ORFEO_Signin() {
  const { mutateAsync } = useSignIn();
  const { token } = useTokenStore();
  const { orfeoAccessToken } = token;

  return (
    <Paper sx={{ p: 1 }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {!orfeoAccessToken ? (
          <Formik
            enableReinitialize={true}
            initialValues={{
              userId: "",
              password: "",
            }}
            validationSchema={Schema.orfeo_Signin}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              mutateAsync(values);
            }}
          >
            {({ errors, touched }) => (
              <Form noValidate autoComplete="no">
                <Box
                  sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "#636190" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                  <Box
                    sx={{
                      mt: 3,
                    }}
                  />
                  {fields.map((f) => (
                    <Field
                      key={f.name}
                      name={f.name}
                      type={f.type}
                      className="formik-input"
                      id={
                        errors[f.name] &&
                        touched[f.name] &&
                        "error-input-border"
                      }
                    >
                      {({ field, meta }) => (
                        <>
                          <TextField
                            {...field}
                            required
                            select={f.select}
                            type={f.type}
                            sx={{ mb: 2, minWidth: 150 }}
                            label={f.name}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder={f.placeholder}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched && meta.error
                            }
                            fullWidth
                            autoComplete="no"
                          />
                        </>
                      )}
                    </Field>
                  ))}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                paddingTop: 4,
                paddingBottom: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "#636190" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                이미 로그인 되어있습니다.
              </Typography>
            </Box>
          </Container>
        )}
      </Container>
    </Paper>
  );
}
