import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as COLORS from "@mui/material/colors";
import AJAX_Manager from "./AJAX_Manager";
import useClasses from "../hooks/useClasses";
// import STATE_Manager from "./STATE_Manager";

const entries = Object.entries(COLORS);
let colors = [];
const datas = entries.map((ent) => {
  return ent[1];
});
datas.forEach((data) => {
  Object.values(data).forEach((item) => (colors = [...colors, item]));
});

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

const rgbToHex = (r, g, b) => {
  const red = parseInt(r, 10);
  const green = parseInt(g, 10);
  const blue = parseInt(b, 10);
  return (
    "#" + componentToHex(red) + componentToHex(green) + componentToHex(blue)
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const classes = useClasses(styles);
  return (
    <div
      role="tabpanel"
      className="tab-panel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.boxP}>
          <Typography>{children}</Typography>
          {props.content}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  content: PropTypes.node,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 380,
    overflowX: "hidden",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  boxP: {
    padding: theme.spacing(2),
  },
});

export default function M_Default() {
  const classes = useClasses(styles);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   //active 로직
  //   const getSelectColor = localStorage.getItem(`${curRadio}_${value}`);
  //   const $active = document.getElementById("list").querySelector("li.active");
  //   if ($active) {
  //     $active.classList.remove("active");
  //   }

  //   if (getSelectColor) {
  //     document.getElementById(getSelectColor).classList.add("active");
  //   }
  // }, [curRadio, value]);

  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="AJAX MANAGER" {...a11yProps(0)} />
          <Tab label="STATE MANAGER" {...a11yProps(1)} />
          <Tab label="UX MANAGER" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0} content={<AJAX_Manager />}>
          AJAX MANAGER
        </TabPanel>
        {/* <TabPanel value={value} index={1} content={<STATE_Manager />}>
          STATE MANAGER
        </TabPanel> */}
      </div>
      <ul
        id="list"
        onClick={(e) => {
          if (!e.target.matches("ul > li")) return;
          const color = e.target.style.backgroundColor;
          const $select = document.getElementById("select-color");
          $select.style.backgroundColor = color;
          const $active = e.target.parentNode.querySelector("li.active");
          if ($active) {
            $active.classList.remove("active");
          }
          e.target.classList.add("active");
        }}
      >
        {colors.map((color, i) => (
          <li
            key={i}
            id={`color-${i}`}
            style={{
              backgroundColor: color,
              listStyle: "none",
            }}
          ></li>
        ))}
      </ul>
      <div
        id="select-color"
        onClick={(e) => {
          const targetElement = e.target;
          const computedStyle = getComputedStyle(targetElement);
          const backgroundColor = computedStyle.backgroundColor;

          // HEX 값을 추출
          const rgbValues = backgroundColor.match(/\d+/g);
          const hexValue = rgbToHex(rgbValues[0], rgbValues[1], rgbValues[2]);
          navigator.clipboard.writeText(hexValue);
          alert(`복사 완료: ${hexValue}`);
        }}
        style={{
          cursor: "pointer",
          border: "1px solid white",
          width: "100%",
          height: 100,
          marginTop: 12,
        }}
      ></div>
    </>
  );
}
