import { Button, Paper, TextField } from "@mui/material";
import {
  useExportStore,
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useState, useEffect, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import { useUpdateProducts } from "../../../../../query/queries/orfeo/productsManage";
import CodeSample from "../../../../../utils/CodeSample";
import Lang from "../../../../../utils/language";

const updateFields = [
  {
    name: "id",
    type: "number",
    disabled: true,
    required: true,
  },
  {
    name: "title",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "category",
    type: null,
    select: true,
    options: Lang.product("category"),
    required: true,
  },
  {
    name: "posterImagePath",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "venueId",
    type: "number",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "productsId",
    type: "number",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "firstPerformance",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "lastPerformance",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "saleStartAt",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "saleEndAt",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "status",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "runningTime",
    type: "number",
    placeholder: "상영 시간을 입력해 주세요.",
    required: true,
  },
  {
    name: "applyEndAt",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "winnerAnnouncementDate",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "ageLimit",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "discountRate",
    type: "number",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "intermission",
    type: "number",
    placeholder: "휴식 시간을 입력해 주세요.",
    required: true,
  },
  {
    name: "detailImagePath",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
];

export default function UpdateProducts() {
  const endpoint = "products";
  const { openModal } = useModalStore();
  const { setIsGuard } = useLeavingGuardStore();
  const { mutateAsync } = useUpdateProducts();
  const { exportData } = useExportStore();
  const importData = exportData[endpoint];
  const [isImport, setIsImport] = useState(false);

  const getExportData = () => {
    if (!exportData[endpoint]) {
      openModal(-1); //export 없는 경우
      return;
    }
    setIsImport(!isImport);
  };

  const updateProducts = useCallback((values) => {
    const payload = { ...values };
    openModal(`공연을 수정 하시겠습니까?`, () => mutateAsync(payload));
  });

  useEffect(() => {
    if (isImport) {
      setIsGuard(true);
    }
  }, [isImport]);

  return (
    <div id="updateProducts">
      <Button variant="contained" color="primary" onClick={getExportData}>
        {!isImport ? "IMPORT" : "CLEAR"}
      </Button>
      {importData && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: importData.id,
            ...importData.data,
          }}
          validationSchema={() => Schema.products(true)}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            updateProducts(values);
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              {isImport && (
                <>
                  <CodeSample sample={values} />
                  <Paper sx={{ p: 1, mt: 1 }}>
                    {updateFields.map((f) => (
                      <div className="rel" key={f.name}>
                        <Field
                          name={f.name}
                          type={f.type}
                          className="formik-input"
                          id={
                            errors[f.name] &&
                            touched[f.name] &&
                            "error-input-border"
                          }
                        >
                          {({ field, meta }) => (
                            <>
                              <TextField
                                {...field}
                                required={f.required}
                                sx={{ mb: 1.6, minWidth: 150 }}
                                label={f.name}
                                placeholder={f.placeholder}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={f.disabled}
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    ))}
                  </Paper>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 1 }}
                    type="submit"
                  >
                    극장 수정
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
