import { TextField } from "@mui/material";

export default function MuiDetailField({ row }: any) {
  return (
    <div className="flex-start pt8">
      {Object.keys(row).map((key) => (
        <TextField
          key={key}
          sx={{ marginBottom: 1, marginRight: 1 }}
          value={JSON.stringify(row[key]) || ""}
          label={key}
        />
      ))}
    </div>
  );
}
