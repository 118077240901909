import { useState } from "react";
import { useEffect } from "react";
import "./DevTool.scss";
import M_Default from "./M_Default.jsx";
import { Button } from "@mui/material";

export default function DevTool() {
  const [isPaletteOpen, setIspaletteOpen] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <Button
        onClick={() => {
          setIspaletteOpen(!isPaletteOpen);
        }}
        sx={{ position: "fixed", bottom: 20, right: 100 }}
        variant="contained"
        type="button"
      >
        dev tools
      </Button>
      {isPaletteOpen && (
        <section className="palette">
          <M_Default />
          <button
            type="button"
            className="btn_settingClose fas fa-times-circle"
            tabIndex="-1"
            onClick={() => {
              setIspaletteOpen(!isPaletteOpen);
            }}
          ></button>
        </section>
      )}
    </>
  );
}
