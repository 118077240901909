import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { purple } from "@mui/material/colors";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import ErrorPage from "./components/pages/ErrorPage";
import Main from "./components/pages/Main";
import React, { useEffect } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import TokenService from "./service/token";
import { useTokenStore } from "./store/creates";
import requests from "./query/request";
import { koKR } from "@mui/x-date-pickers/locales";
import MuiDial from "./components/common/MuiDial";

const theme = createTheme(
  {
    typography: {
      fontFamily: "'Noto Sans KR', sans-serif",
    },
    palette: {
      primary: {
        main: purple[900],
      },
      secondary: {
        main: purple[500],
      },
    },
  },
  koKR
);

function updateToken(type: "olea" | "orfeo" | "calliope") {
  const token = TokenService.get(type);
  if (token.accessToken) {
    requests[type].defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token.accessToken}`;
    const result = {
      [`${type}AccessToken`]: token.accessToken,
      [`${type}RefreshToken`]: token.refreshToken,
    };
    return result;
  }
}

function App() {
  const [queryClient] = React.useState(() => new QueryClient());
  const { setToken } = useTokenStore();

  function init() {
    const oleaToken = updateToken("olea");
    const orfeoToken = updateToken("orfeo");
    const mergeToken = {
      ...oleaToken,
      ...orfeoToken,
    };

    setToken(mergeToken);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <BrowserRouter>
            <Main />
            <MuiDial />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
