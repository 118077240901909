import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { collapseToggle } from "../../../utils/CollapseContainer.jsx";
import CollapseContainer from "../../../utils/CollapseContainer.jsx";
import {
  useLeavingGuardStore,
  useModalStore,
  useRouteFollowerStore,
} from "../../../store/creates.js";
import { useVertical } from "../../hooks/useVertical.jsx";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%", overflow: "auto" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs(props) {
  const { verticalValue, clearVertical } = useRouteFollowerStore();
  const { idx, autoHeight } = props;
  const [value, setValue] = useState(0);
  const { openModal } = useModalStore();
  const { isGuard, setIsGuard } = useLeavingGuardStore();
  const { isCollapse, setIsCollapse, classes } = useVertical();

  const handleChange = (event, newValue) => {
    if (isGuard) {
      openModal("입력한 데이터가 사라져도 괜찮습니까?", () => {
        setValue(newValue);
        setIsGuard(false);
      });
    } else {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (verticalValue === null) return;
    handleChange(null, verticalValue);
    clearVertical();
  }, [verticalValue]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: autoHeight ? "auto" : "calc(100vh - 160px)",
        minHeight: 200,
      }}
    >
      {idx && (
        <>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            TabIndicatorProps={{ style: { background: "#636190" } }}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {idx.ctrDeps.map((data, i) => (
              <Tab key={data} label={data} {...a11yProps(i)} />
            ))}
          </Tabs>
          {idx.ctrDeps.map((data, i) => (
            <TabPanel key={data} value={value} index={i}>
              {idx.details[i].map((detail, j) => (
                <div
                  key={detail.label + i}
                  className="menu"
                  style={{ marginBottom: 8 }}
                >
                  <Typography
                    variant="h7"
                    className={
                      `btn_toggle${
                        isCollapse.some((data) => data === j) ? " on" : ""
                      }` +
                      " " +
                      clsx(classes.label)
                    }
                    onClick={(e) => {
                      setIsCollapse(collapseToggle(isCollapse, j));
                    }}
                  >
                    {detail.label}
                  </Typography>
                  <CollapseContainer
                    content={detail.component}
                    isOpen={isCollapse.some((data) => data === j) || false}
                  />
                </div>
              ))}
            </TabPanel>
          ))}
        </>
      )}
    </Box>
  );
}

export default VerticalTabs;
