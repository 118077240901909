import { getRefreshToken } from "../../../service/token";
import reqeusts from "../../request";

//상세 조회 (pageType은 followerStore에서 가져오며 api endpoint와 id로 디테일 정보 조회함)
export const getDetailsById = async (pageType, endpoint, id) => {
  const data = await reqeusts[pageType].get(`${endpoint}/${id}`);
  return data;
};

//토큰 재발급 (올레아만 사용 중이며 추후에 다른 페이지 타입에도 재발급 로직 추가 시 endpoint 로직 변경 필요할 수 있음)
export const reissuanceToken = async (pageType) => {
  const data = await reqeusts[pageType].get(`auth/reissueToken`, {
    headers: {
      Authorization: `Bearer ${getRefreshToken()}`,
    },
  });
  return data;
};
