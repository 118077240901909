import requests from "../query/request";
import { getCookie, removeCookie, setCookie } from "../utils/cookie";

export default class TokenService {
  static save(type, accessToken, refreshToken) {
    if (accessToken) {
      setCookie(`${type}AccessToken`, accessToken, {
        path: "/",
      });
    }
    if (refreshToken) {
      setCookie(`${type}RefreshToken`, refreshToken, {
        path: "/",
      });
    }
  }

  static get(type) {
    return {
      accessToken: getCookie(`${type}AccessToken`, { path: "/" }),
      refreshToken: getCookie(`${type}RefreshToken`, { path: "/" }),
    };
  }

  static clear(type) {
    requests[type].defaults.headers.common["Authorization"] = `Bearer`;
    removeCookie(`${type}AccessToken`, { path: "/" });
    removeCookie(`${type}RefreshToken`, { path: "/" });
  }
}

export function getAccessToken(type) {
  const { accessToken } = TokenService.get(type);
  return accessToken;
}

export function getRefreshToken(type) {
  const { refreshToken } = TokenService.get(type);
  return refreshToken;
}
