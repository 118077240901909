import reqeusts from "../../request";

//오르페오 로그인
export const signIn = async (payload) => {
  const data = await reqeusts.orfeo.post("users/sign-in", payload);
  return data;
};

//고객센터 > 공지 > 공지 추가
export const createAnnouncements = async (payload) => {
  const data = await reqeusts.orfeo.post("announcements", payload);
  return data;
};

//고객센터 > 이벤트 > 이벤트 추가
export const createEvents = async (payload) => {
  const data = await reqeusts.orfeo.post("events", payload);
  return data;
};

//고객센터 > FAQ > FAQ 추가
export const createFaq = async (payload) => {
  const data = await reqeusts.orfeo.post("faq", payload);
  return data;
};

//공연 관리 > 공연 > 공연 추가
export const createProducts = async (payload) => {
  const data = await reqeusts.orfeo.post("products", payload);
  return data;
};

//공연 관리 > 공연 > 공연 회차 추가
export const createProductRound = async (payload) => {
  const data = await reqeusts.orfeo.post("product-round", payload);
  return data;
};

//극장 관리 > 극장 추가
export const createVenue = async (payload) => {
  const data = await reqeusts.orfeo.post("venue", payload);
  return data;
};

//극장 관리 > 상영관 추가
export const createTheater = async (payload) => {
  const data = await reqeusts.orfeo.post("theater", payload);
  return data;
};

//극장 관리 > 상영관 층계 정보 추가
export const createFloor = async (payload) => {
  const data = await reqeusts.orfeo.post("floor-category", payload);
  return data;
};

//극장 관리 > 좌석 추가
export const createTheaterInfo = async (payload) => {
  const data = await reqeusts.orfeo.post("theater-info/array", payload);
  return data;
};
