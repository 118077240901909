import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import randomString from "../../utils/randomString";
import MuiTableSplitButton, { CustomTooltip } from "./MuiTableSplitButton";
import TablePagination from "@mui/material/TablePagination";
import useClasses from "../hooks/useClasses";
import { useGetDetailInfo } from "../../query/queries/common";
import MuiDetailField from "./MuiDetailField";
import { Tab, Tabs, Typography } from "@mui/material";
import { mergeId } from "../../utils/mapping";
import { useTableStore } from "../../store/creates";

interface RowCollapseBasicProps {
  data: {
    id: number;
    row: any;
    uniKey: string;
    queryKey: string;
  };
}

interface RowCollapseDetailProps {
  data: {
    id: number;
    uniKey: string;
    queryKey: string;
    innerEndpoint: any;
  };
}

function RowCollapseBasic({ data }: RowCollapseBasicProps) {
  const { id, row, uniKey } = data;

  return (
    <Box sx={{ margin: 1 }}>
      <MuiTableSplitButton id={id} data={row} uniKey={uniKey} />
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function RowCollapseDetail({ data }: RowCollapseDetailProps) {
  const { id, uniKey, queryKey, innerEndpoint } = data;
  const { tables } = useTableStore();
  const { data: resData, isSuccess } = useGetDetailInfo(queryKey, id);
  const originData = React.useMemo(
    () => mergeId(uniKey, resData || {}),
    [resData]
  );
  const [value, setValue] = React.useState(0);
  const filteredTables: any = React.useMemo(() => {
    if (innerEndpoint.length === 0) return;
    return tables.filter((data: any) =>
      innerEndpoint.some((inner: string) => `${inner}/${id}` === data.id)
    );
  }, [tables]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper
      sx={{
        margin: 1,
        position: "relative",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          width: "fit-content",
        }}
      >
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", bgcolor: "#ede7f6" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="상세" />
            {filteredTables?.map((data: { tabName: string; id: string }) => (
              <Tab label={data.tabName} key={data.tabName + data.id} />
            ))}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {isSuccess && <MuiDetailField row={originData} />}
        </CustomTabPanel>
        {filteredTables?.map((data: any, i: number) => (
          <React.Fragment key={data.tabName + data.id}>
            <CustomTabPanel value={value} index={i + 1}>
              {data.table}
            </CustomTabPanel>
          </React.Fragment>
        ))}
      </Box>
    </Paper>
  );
}

function Row(props: {
  row: any;
  id: number;
  uniKey: string;
  queryKey: string;
  isCollapse: boolean;
  innerEndpoint: any;
}) {
  const { row, id, uniKey, queryKey, isCollapse, innerEndpoint } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell>
          {isCollapse && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {Object.keys(row).map((key) => (
          <TableCell
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            component="td"
            scope="row"
            key={key}
          >
            <CustomTooltip
              placement="left"
              arrow
              title={
                <React.Fragment>
                  <Typography color="inherit">상세 정보</Typography>
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(row[key], null, 2)}
                  </span>
                </React.Fragment>
              }
            >
              <span>{JSON.stringify(row[key])}</span>
            </CustomTooltip>
          </TableCell>
        ))}
      </TableRow>
      {isCollapse && (
        <TableRow>
          <TableCell
            sx={{ paddingBottom: 0, paddingTop: 0, width: "min-content" }}
            colSpan={8}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ width: "fit-content" }}
            >
              {open && (
                <>
                  <RowCollapseBasic data={{ row, id, uniKey, queryKey }} />
                  {queryKey && (
                    //api endpoint가 있을 경우
                    <RowCollapseDetail
                      data={{ id, uniKey, queryKey, innerEndpoint }}
                    />
                  )}
                </>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const styles = (theme: any) => ({
  root: {
    "& .MuiTablePagination-toolbar": {
      background: "#d1c4e9",
    },
  },
});

export default function MuiTable({
  headers = [""], //thead
  rows = [], //tbody
  uniKey = randomString(), //api data id
  queryKey = "", //api endpoint
  isCollapse = true, //collaspe 여부
  innerEndpoint = [], //내부 api endpoint 옵저빙
}) {
  const classes: any = useClasses(styles);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <Row
                key={row[uniKey]}
                row={row}
                id={row[uniKey]}
                uniKey={uniKey}
                queryKey={queryKey}
                isCollapse={isCollapse}
                innerEndpoint={innerEndpoint}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.root}
      />
    </>
  );
}
