import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import HistoryIcon from "@mui/icons-material/History";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import randomString from "../../utils/randomString";
import testService from "../../service/testService";
import useClasses from "../hooks/useClasses";

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(2),
    background: "#4a148c",
    textTransform: "none",
    "&:hover": {
      background: "#6a1b9a",
    },
  },
  historyBtn: {
    background: "#2962ff",
    "&:hover": {
      background: "#448aff",
    },
  },
  historyList: {
    position: "fixed",
    right: 951,
    top: 50,
    overflow: "auto",
    height: 800,
    maxWidth: 600,
    padding: 12,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    backgroundColor: "#424242",
    color: "#fff",
    paddingTop: 50,
    "& .MuiListItem-button:hover": {
      background: "#757575",
    },
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      background: "#303030",
    },
    "&::-webkit-scrollbar-thumb ": {
      background: "#aaaaaa",
    },
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#d5d5d5",
    },
  },
});

function checkValueType(value) {
  if (Array.isArray(value)) {
    return JSON.stringify(value).replaceAll("},", "},\n");
  } else if (typeof value === "object" && value !== null) {
    return JSON.stringify(value).replaceAll(`,"`, `,\n"`);
  } else {
    return value;
  }
}

export default function AJAX_Manager() {
  const classes = useClasses(styles);
  const [baseData, setBaseData] = useState({
    url: "",
    method: "METHOD",
    payload: null,
  });
  const [isGeneratorParams, setIsGeneratorParams] = useState([]);
  const [isGeneratorPayload, setIsGeneratorPayload] = useState([]);
  const [isJson, setIsJson] = useState("JSON");
  const [apiHistory, setApiHistory] = useState([]);

  function onChangeFilter(e, data, key, datas, setState) {
    const filter = datas.filter((param) => {
      if (data.id === param.id) {
        param[key] = e.target.value;
      }
      return param;
    });
    setState(filter);
  }

  function parseInput(input) {
    try {
      const parsedObject = JSON.parse(input);
      if (input.startsWith("[") && input.endsWith("]")) {
        // "["와 "]" 사이의 내용을 파싱하여 배열로 반환
        return JSON.parse(input);
      }
      return parsedObject;
    } catch (error) {
      return input;
    }
  }

  async function send() {
    const { url, method } = baseData;
    if (!url) {
      alert("url 미설정");
      return;
    }
    if (!method || method === "METHOD") {
      alert("method 미설정");
      return;
    }

    const params = {};
    const paramsDuplicateKeys = [];
    for (const item of isGeneratorParams) {
      if (item.key && item.value) {
        if (params[item.key]) {
          paramsDuplicateKeys.push(item.key);
        } else {
          params[item.key] = parseInput(item.value);
        }
      }
    }

    let payload = {};
    if (baseData.payload) {
      payload = JSON.parse(baseData.payload);
    } else {
      const payloadDuplicateKeys = [];
      for (const item of isGeneratorPayload) {
        if (item.key && item.value !== "") {
          if (payload[item.key]) {
            payloadDuplicateKeys.push(item.key);
          } else {
            payload[item.key] = parseInput(item.value);
          }
        }
      }
      if (paramsDuplicateKeys.length > 0) {
        alert(`params 중복된 키: ${paramsDuplicateKeys.join(", ")}`);
      }
      if (payloadDuplicateKeys.length > 0) {
        alert(`payload 중복된 키: ${payloadDuplicateKeys.join(", ")}`);
      }
      if (paramsDuplicateKeys.length > 0 || payloadDuplicateKeys.length > 0) {
        return;
      }
    }

    const sendData = {
      url: url,
      method: method,
      params: params,
    };
    if (isJson === "form-data") {
      const formData = new FormData();
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          formData.append(key, payload[key]);
        }
      }
      sendData.formData = true;
      sendData.payload = formData;
    } else {
      sendData.payload = payload;
    }

    try {
      const res = await testService.custom(sendData);
      alert("요청 성공");
      console.log(res);
    } catch (err) {
      alert("요청 실패");
      console.log(err);
    } finally {
      const history = JSON.parse(localStorage.getItem("API_MANAGER")) || [];
      history.push(sendData);
      localStorage.setItem("API_MANAGER", JSON.stringify(history));
      if (!history) return;
      setApiHistory(history.reverse());
    }
  }

  return (
    <div className="ajax-manager">
      <div>
        <input
          type="text"
          placeholder="url"
          value={baseData.url}
          onChange={(e) => {
            setBaseData({ ...baseData, url: e.target.value.trim() });
          }}
        />
        <Select
          native
          value={baseData.method}
          style={{ width: "100%", marginBottom: 8 }}
          onChange={(e) => {
            setBaseData({ ...baseData, method: e.target.value.trim() });
          }}
        >
          <option value={"METHOD"} disabled hidden>
            METHOD
          </option>
          {["GET", "PUT", "POST", "DELETE"].map((data) => (
            <option key={data} value={data}>
              {data}
            </option>
          ))}
        </Select>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<AddIcon />}
          onClick={() =>
            setIsGeneratorParams([
              ...isGeneratorParams,
              { key: "", value: "", id: randomString() },
            ])
          }
        >
          params 생성
        </Button>
        {isGeneratorParams.length > 0 && (
          <div>
            {isGeneratorParams.map((data, i) => (
              <div className="object-container" key={data.id}>
                <input
                  type="text"
                  placeholder="key"
                  value={data.key}
                  onChange={(e) => {
                    onChangeFilter(
                      e,
                      data,
                      "key",
                      isGeneratorParams,
                      setIsGeneratorParams
                    );
                  }}
                />
                <input
                  type="text"
                  placeholder="value"
                  value={data.value}
                  onChange={(e) => {
                    onChangeFilter(
                      e,
                      data,
                      "value",
                      isGeneratorParams,
                      setIsGeneratorParams
                    );
                  }}
                />
              </div>
            ))}
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<AddIcon />}
          onClick={() =>
            setIsGeneratorPayload([
              ...isGeneratorPayload,
              { key: "", value: "", id: randomString() },
            ])
          }
        >
          payload 생성
        </Button>

        {isGeneratorPayload.length > 0 && (
          <div>
            <textarea
              value={baseData.payload || ""}
              cols="30"
              rows="10"
              placeholder="payload-ALL"
              onChange={(e) => {
                setBaseData({ ...baseData, payload: e.target.value });
              }}
            ></textarea>
            <RadioGroup
              aria-label="Options"
              name="Options"
              value={isJson}
              onChange={(e) => {
                setIsJson(e.target.value);
              }}
            >
              <FormControlLabel value="JSON" control={<Radio />} label="JSON" />
              <FormControlLabel
                value="form-data"
                control={<Radio />}
                label="form-data"
              />
            </RadioGroup>

            {!baseData.payload &&
              isGeneratorPayload.map((data) => (
                <div className="object-container" key={data.id}>
                  <input
                    type="text"
                    placeholder="key"
                    value={data.key}
                    onChange={(e) => {
                      onChangeFilter(
                        e,
                        data,
                        "key",
                        isGeneratorPayload,
                        setIsGeneratorPayload
                      );
                    }}
                  />
                  <input
                    type="text"
                    placeholder="value"
                    value={data.value}
                    onChange={(e) => {
                      onChangeFilter(
                        e,
                        data,
                        "value",
                        isGeneratorPayload,
                        setIsGeneratorPayload
                      );
                    }}
                  />
                </div>
              ))}
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<SendIcon />}
          onClick={send}
        >
          Send
        </Button>
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.historyBtn + " last"}
        endIcon={<HistoryIcon />}
        onClick={(e) => {
          const data = JSON.parse(localStorage.getItem("API_MANAGER"));
          if (!data) return;
          setApiHistory(data.reverse());
        }}
      >
        history
      </Button>
      {apiHistory.length > 0 && (
        <div className={classes.historyList}>
          <button
            type="button"
            className="btn_settingClose fas fa-times-circle"
            onClick={() => {
              setApiHistory([]);
            }}
          ></button>
          {apiHistory.map((data, i) => (
            <Accordion key={i}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ color: "#01579b", userSelect: "all" }}
              >
                <Typography>{data.url}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block", color: "#1a237e" }}>
                <p className="h">METHOD {data.method}</p>
                <br />
                <p className="h">params</p>
                <div className="data-box">
                  <table>
                    <thead>
                      <tr>
                        <th>key</th>
                        <th>value</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.keys(data.params).map((key) => (
                        <tr key={key}>
                          <td>{key}</td>
                          {/* <td>{JSON.parse(data.params[key])}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p className="h">payload</p>
                <div className="data-box">
                  <table>
                    <thead>
                      <tr>
                        <th>key</th>
                        <th>value</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.keys(data.payload).map((key) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>{checkValueType(data.payload[key])} </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<DoubleArrowIcon />}
                  onClick={(e) => {
                    setBaseData({ url: data.url, method: data.method });
                    const params = Object.keys(data.params).map((key) => ({
                      key,
                      value: checkValueType(data.params[key]),
                      id: randomString(),
                    }));
                    const payload = Object.keys(data.payload).map((key) => ({
                      key,
                      value: checkValueType(data.payload[key]),
                      id: randomString(),
                    }));

                    setIsGeneratorParams(params);
                    setIsGeneratorPayload(payload);
                  }}
                >
                  EXPORT
                </Button>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </div>
  );
}
