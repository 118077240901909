import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getDetailsById } from "../apis/common/GET";
import { useModalStore, useRouteFollowerStore } from "../../store/creates";

export const useGetDetailInfo = (queryKey, id) => {
  const { pageType } = useRouteFollowerStore();

  return useQuery({
    queryKey: ["get", `${queryKey}/${id}`],
    queryFn: async () => {
      const { data } = await getDetailsById(pageType, queryKey, id);

      return data;
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
};

export const useGetDetailInfoCall = () => {
  const { pageType } = useRouteFollowerStore();
  const queryClient = useQueryClient();

  return useMutation(
    (data) => getDetailsById(pageType, data.queryKey, data.id),
    {
      onSuccess: ({ data }, { queryKey, id }) => {
        queryClient.invalidateQueries(["get", `${queryKey}/${id}`]);

        return data;
      },
      onError: (e) => {
        onErrorBind(e);
      },
    }
  );
};

export function onErrorBind(e) {
  console.log(e);
}
