import { Button, Paper, TextField } from "@mui/material";
import TinyEditor from "../../../../../utils/TinyEditor";
import {
  useExportStore,
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useRef, useState, useEffect, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import { useUpdateEvents } from "../../../../../query/queries/orfeo/customerService";

const updateFields = [
  {
    name: "id",
    type: "number",
    sx: { mb: 1.6, display: "block" },
    disabled: true,
    required: true,
  },
  {
    name: "title",
    type: "text",
    placeholder: "eventId",
    sx: { mb: 1.6 },
    required: true,
  },
  {
    name: "writer",
    type: "text",
    placeholder: "작성자를 입력해주세요.",
    sx: { mb: 1.6 },
    required: true,
  },
];

export default function UpdateEvents() {
  const endpoint = "events";
  const { openModal } = useModalStore();
  const { setIsGuard } = useLeavingGuardStore();
  const { mutateAsync } = useUpdateEvents();
  const { exportData } = useExportStore();
  const editorRef = useRef(null);
  const importData = exportData[endpoint];
  const [isImport, setIsImport] = useState(false);

  useEffect(() => {
    //export 데이터 초기화
    const importData = exportData[endpoint];
    if (!importData) return;
  }, [exportData]);

  const getExportData = () => {
    if (!exportData[endpoint]) {
      openModal(-1); //export 없는 경우
      return;
    }
    setIsImport(!isImport);
  };

  const updateEvents = useCallback((values) => {
    if (editorRef.current) {
      const payload = { ...values };
      openModal(`이벤트를 수정 하시겠습니까?`, () => mutateAsync(payload));
    }
  }, []);

  useEffect(() => {
    if (isImport) {
      setIsGuard(true);
    }
  }, [isImport]);

  return (
    <div id="updateEvents">
      <Button variant="contained" color="primary" onClick={getExportData}>
        {!isImport ? "IMPORT" : "CLEAR"}
      </Button>
      {importData && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: importData.id,
            title: importData.data.title,
            content: importData.data.content,
            writer: importData.data.writer,
          }}
          validationSchema={() => Schema.events(true)}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            updateEvents(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              {isImport && (
                <>
                  <Paper sx={{ p: 1, mt: 1 }}>
                    {updateFields.map((f) => (
                      <div className="rel" key={f.name}>
                        <Field
                          name={f.name}
                          type={f.type}
                          className="formik-input"
                          id={
                            errors[f.name] &&
                            touched[f.name] &&
                            "error-input-border"
                          }
                        >
                          {({ field, meta }) => (
                            <>
                              <TextField
                                {...field}
                                required={f.required}
                                sx={f.sx}
                                label={f.name}
                                placeholder={f.placeholder}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={f.disabled}
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    ))}

                    <TinyEditor
                      editorRef={editorRef}
                      initValue={importData.data.content}
                      name="content"
                    />
                  </Paper>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 1 }}
                    type="submit"
                  >
                    이벤트 수정
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
