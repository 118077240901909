import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import "dayjs/locale/ko";

export default function MuiDateTimeField({ name, setFieldValue, fieldValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <DemoContainer
        components={["DateTimeField", "DateTimeField", "DateTimeField"]}
        sx={{ pt: 0, overflow: "unset", maxWidth: 150, mb: 1.6 }}
      >
        <DateTimeField
          label={name}
          value={dayjs(fieldValue)}
          onChange={(date) => {
            if (!date) {
              setFieldValue(name, "", true);
            } else {
              setFieldValue(name, date, true);
            }
          }}
          format="LL HH:mm"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
