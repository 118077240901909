import * as React from "react";
import { useModalStore } from "../store/creates";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function messageConvert(message: string | number) {
  switch (message) {
    case 0:
      return "상태 변경이 불가합니다. 상태를 다시 확인해주세요.";
    case -1:
      return "EXPORT 데이터 없음";
    default:
      return message;
  }
}

function ConfirmModal() {
  const { isModalOpen, message, onConfirm, closeModal } = useModalStore(); // Zustand 스토어 사용

  if (!isModalOpen) {
    return null;
  }

  const handleConfirm = () => {
    onConfirm(); // 확인 작업 실행
    closeModal(); // 모달 닫기
  };

  const handleCancel = () => {
    closeModal(); // 모달 닫기
  };

  return (
    <Dialog
      maxWidth={false}
      open={isModalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCancel}
      aria-describedby="alert-dialog-slide-description"
    >
      {typeof message === "string" && <DialogTitle>확인 안내</DialogTitle>}
      <DialogContent>
        {typeof message === "string" ? (
          <DialogContentText id="alert-dialog-slide-description">
            {messageConvert(message)}
          </DialogContentText>
        ) : (
          messageConvert(message)
        )}
      </DialogContent>
      <DialogActions>
        {typeof message === "number" || !onConfirm ? (
          <Button onClick={handleCancel} color="error">
            닫기
          </Button>
        ) : (
          <>
            <Button onClick={handleCancel} color="error">
              NO
            </Button>
            <Button onClick={handleConfirm}>YES</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
