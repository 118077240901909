import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTokenStore } from "../../../../store/creates";
import TokenService from "../../../../service/token";

export default function OLEA_Signout() {
  const { token, clearOleaToken } = useTokenStore();
  const { oleaAccessToken } = token;

  return (
    <Paper sx={{ p: 1 }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {!oleaAccessToken ? (
          <Box
            sx={{
              paddingTop: 4,
              paddingBottom: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#636190" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              로그인이 되어있지 않습니다.
            </Typography>
          </Box>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e) => {
              TokenService.clear("olea");
              clearOleaToken();
            }}
          >
            Sign Out
          </Button>
        )}
      </Container>
    </Paper>
  );
}
