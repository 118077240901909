import Typography from "@mui/material/Typography";
import { useVertical } from "../hooks/useVertical";
import { useFavorite } from "../hooks/useFavorite";
import VerticalTabs from "./ctr/VerticalTabs";

export default function SigninManage() {
  const label = "로그인 관리";
  const { verticalDatas } = useVertical();
  const { IconBtn } = useFavorite(label);

  return (
    <div className="wrap">
      <Typography
        variant="h5"
        style={{
          marginBottom: 8,
          fontWeight: 600,
        }}
      >
        {label}
        <IconBtn />
      </Typography>
      <VerticalTabs idx={verticalDatas.signin_manage} />
    </div>
  );
}
