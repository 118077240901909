import { Button, Paper, TextField } from "@mui/material";
import {
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useCallback, useMemo } from "react";
import {
  useCreateTheater,
  useGetVenue,
} from "../../../../../query/queries/orfeo/venueManage";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import CodeSample from "../../../../../utils/CodeSample";
import MenuItem from "@mui/material/MenuItem";

const initValues = {
  name: "",
  venueId: "",
  x: "",
  y: "",
};

export default function PostTheater() {
  const { openModal } = useModalStore();
  const { data: venueData } = useGetVenue();
  const { mutateAsync } = useCreateTheater();
  const { setIsGuard } = useLeavingGuardStore();

  const fields = useMemo(
    () => [
      {
        name: "name",
        type: "text",
        placeholder: "상영관 이름을 입력해 주세요.",
        required: true,
      },
      {
        name: "venueId",
        type: "text",
        select: true,
        options: venueData || [],
        required: true,
      },
      {
        name: "x",
        type: "number",
        placeholder: "전체 좌석 행의 크기 입니다.",
        required: true,
      },
      {
        name: "y",
        type: "number",
        placeholder: "전체 좌석 열의 크기 입니다.",
        required: true,
      },
    ],
    [venueData]
  );

  const addTheater = useCallback((values) => {
    const payload = { ...values };
    mutateAsync(payload);
  }, []);

  return (
    <div id="postTheater">
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={() => Schema.theater()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          openModal(`상영관을 추가 하시겠습니까?`, () => addTheater(values));
        }}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form onChange={() => setIsGuard(true)}>
            <CodeSample sample={values} />
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  <Field
                    name={f.name}
                    type={f.type}
                    className="formik-input"
                    id={
                      errors[f.name] && touched[f.name] && "error-input-border"
                    }
                  >
                    {({ field, meta }) => (
                      <TextField
                        {...field}
                        required={f.required}
                        type={f.type}
                        select={f.select}
                        sx={{ mb: 1.6, minWidth: 150 }}
                        label={f.name}
                        placeholder={f.placeholder}
                        InputLabelProps={{ shrink: true }}
                        error={meta.error && meta.touched}
                        helperText={meta.error && meta.touched && meta.error}
                      >
                        {f.options?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            [극장 ID: {option.id}] {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </div>
              ))}
            </Paper>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              상영관 등록
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
