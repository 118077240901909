import React from "react";
import { ErrorMessage } from "formik";

function StyledErrorMessage({ name, id }) {
  return (
    <div className="formik-error-message" id={id}>
      <ErrorMessage name={name} />
    </div>
  );
}

export default React.memo(StyledErrorMessage);
