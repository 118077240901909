import { memo } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useField } from "formik";
import StyledErrorMessage from "./StyledErrorMessage";

function TinyEditor({ editorRef, initValue = "", name }) {
  const [field, meta, helpers] = useField(name);

  function handleChange(v) {
    helpers.setValue(v);
  }

  function handleBlur() {
    helpers.setTouched(true);
  }

  return (
    <div id={meta.error && meta.touched ? "error-border" : ""} className="rel">
      <Editor
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initValue}
        onEditorChange={handleChange}
        onBlur={handleBlur}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "link | " +
            "image | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <StyledErrorMessage
        name={field.name}
        id={meta.error && meta.touched ? "error-on" : ""}
      />
    </div>
  );
}

export default memo(TinyEditor);
