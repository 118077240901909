import { Button, Paper, TextField } from "@mui/material";
import {
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useCallback } from "react";
import { useCreateProducts } from "../../../../../query/queries/orfeo/productsManage";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import MenuItem from "@mui/material/MenuItem";
import Lang from "../../../../../utils/language";
import CodeSample from "../../../../../utils/CodeSample";

const fields = [
  {
    name: "title",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "category",
    type: null,
    select: true,
    options: Lang.product("category"),
    required: true,
  },
  {
    name: "posterImagePath",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "venueId",
    type: "number",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "theaterId",
    type: "number",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "firstPerformance",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "lastPerformance",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "saleStartAt",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "saleEndAt",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "status",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "runningTime",
    type: "number",
    placeholder: "상영 시간을 입력해 주세요.",
    required: true,
  },
  {
    name: "applyEndAt",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "winnerAnnouncementDate",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "ageLimit",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "discountRate",
    type: "number",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "intermission",
    type: "number",
    placeholder: "휴식 시간을 입력해 주세요.",
    required: true,
  },
  {
    name: "detailImagePath",
    type: "text",
    placeholder: "제목을 입력해 주세요.",
    required: true,
  },
];
//
const initValues = {
  title: "테스트 생성",
  category: "",
  posterImagePath:
    "https://orfeo-user.s3.ap-northeast-2.amazonaws.com/ORFEO-musical-1.png",
  venueId: 0,
  theaterId: 0,
  firstPerformance: "2023-10-20T02:13:50.314Z",
  lastPerformance: "2023-10-23T02:13:50.314Z",
  saleStartAt: "2023-10-20T02:13:50.314Z",
  saleEndAt: "2023-10-20T02:13:50.314Z",
  status: "판매 중",
  runningTime: 120,
  applyEndAt: "2023-10-20T02:13:50.314Z",
  winnerAnnouncementDate: "2023-10-24T02:13:50.314Z",
  ageLimit: "18세 이상",
  discountRate: 15,
  intermission: 20,
  detailImagePath:
    "https://orfeo-user.s3.ap-northeast-2.amazonaws.com/ORFEO-musical-1-detail.png",
};

export default function PostProducts() {
  const { openModal } = useModalStore();
  const { mutateAsync } = useCreateProducts();
  const { setIsGuard } = useLeavingGuardStore();

  const addProducts = useCallback((values) => {
    const payload = { ...values };
    mutateAsync(payload);
  }, []);

  return (
    <div id="postProducts">
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={() => Schema.products()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          openModal(`공연을 추가 하시겠습니까?`, () => addProducts(values));
        }}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form onChange={() => setIsGuard(true)}>
            <CodeSample sample={values} />
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  <Field
                    name={f.name}
                    type={f.type}
                    className="formik-input"
                    id={
                      errors[f.name] && touched[f.name] && "error-input-border"
                    }
                  >
                    {({ field, meta }) => (
                      <>
                        <TextField
                          {...field}
                          required={f.required}
                          type={f.type}
                          select={f.select}
                          sx={{ mb: 1.6, minWidth: 150 }}
                          label={f.name}
                          InputLabelProps={{ shrink: true }}
                          placeholder={f.placeholder}
                          error={meta.error && meta.touched}
                          helperText={meta.error && meta.touched && meta.error}
                        >
                          {f.options?.map((option) => (
                            <MenuItem key={option.en} value={option.en}>
                              {option.kr}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                  </Field>
                </div>
              ))}
            </Paper>
            {/* <input
              id="file"
              name="posterImagePath"
              type="file"
              onChange={(event) => {
                const file = event.target.files[0];
                const formData = new FormData();
                formData.append("posterImagePath", file);
                setFieldValue("posterImagePath", file);
              }}
              multiple
            /> */}
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              공연 등록
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
