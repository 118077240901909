import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createProductRound, createProducts } from "../../apis/orfeo/POST";
import { onErrorBind } from "../common";
import {
  getProductRound,
  getProductRoundTotal,
  getProducts,
} from "../../apis/orfeo/GET";
import { updateProductRound, updateProducts } from "../../apis/orfeo/UPDATE";
import { deleteProductRound, deleteProducts } from "../../apis/orfeo/DELETE";
import { useModalStore, useTableStore } from "../../../store/creates";
import { tableDepsMap } from "../../../utils/mapping";
import GetProductRound from "../../../components/menu/orfeo/productsManage/ProductRound/GetProductRound";

//공연
export function useCreateProducts() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createProducts(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "products/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetProducts() {
  return useQuery({
    queryKey: ["get", `products/`],
    queryFn: async () => {
      const { data } = await getProducts();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateProducts() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateProducts(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "products/"]);
      queryClient.invalidateQueries(["get", `products/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteProducts() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteProducts(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "products/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

//공연 회차
export function useCreateProductRound() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createProductRound(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "product-round/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetProductRound() {
  const queryClient = useQueryClient();
  const { openModal } = useModalStore();
  const { tables, setTables } = useTableStore();

  return useMutation((params) => getProductRound(params), {
    onSuccess: ({ data }, payload) => {
      if (data.length === 0) {
        openModal(
          "공연 회차 정보가 존재하지 않습니다. 먼저 공연 회차 정보를 생성해 주세요."
        );
        return;
      }
      const copy = tables.slice();
      const obj = {
        tabName: `${payload.selectedDate || "모든"} 회차 조회`,
        id: `product-round/${payload.productId}`,
        table: <GetProductRound data={data} />,
      };

      const mapping = tableDepsMap(copy, obj);
      if (!mapping) {
        setTables([...copy, obj]);
        return;
      } else {
        setTables(mapping);
      }

      return data;
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetProductRoundTotal() {
  const queryClient = useQueryClient();
  const { openModal } = useModalStore();
  const { tables, setTables } = useTableStore();

  return useMutation((params) => getProductRoundTotal(params), {
    onSuccess: ({ data }, payload) => {
      if (data.length === 0) {
        openModal("해당 날짜의 공연 회차 정보가 존재하지 않습니다.");
        return;
      }
      const copy = tables.slice();
      const obj = {
        tabName: `${payload.selectedDate || "모든"} 회차 조회`,
        id: `product-round/total/${payload.productId}`,
        table: <GetProductRound data={data} />,
      };

      const mapping = tableDepsMap(copy, obj);
      if (!mapping) {
        setTables([...copy, obj]);
        return;
      } else {
        setTables(mapping);
      }

      return data;
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useUpdateProductRound() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateProductRound(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "product-round/"]);
      queryClient.invalidateQueries(["get", `product-round/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteProductRound() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteProductRound(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "product-round/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}
