import { useMemo, useEffect } from "react";
import {
  useExportStore,
  useModalStore,
  useOptionsStore,
} from "../../../../../store/creates";
import MuiTable from "../../../../common/MuiTable";
import {
  useDeleteProducts,
  useGetProducts,
} from "../../../../../query/queries/orfeo/productsManage";
import mergeIdMap from "../../../../../utils/mapping";
import GetProductRoundSender from "../ProductRound/GetProductRoundSender";

export default function GetProducts() {
  const endpoint = "products";
  const uniKey = "productId";
  const { data: resData, isSuccess } = useGetProducts();
  const { openModal } = useModalStore();
  const { mutateAsync } = useDeleteProducts();
  const originData = useMemo(
    () => mergeIdMap(uniKey, resData || []),
    [resData]
  );
  const { setOptions, options } = useOptionsStore();
  const { setExport } = useExportStore();
  const innerEndpoint = ["product-round/total", "product-round"];

  const deleteProducts = (id) => {
    mutateAsync(id);
  };

  useEffect(() => {
    if (options[uniKey]) return;
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "EXPORT",
        action: (id, data) => {
          const EXPORT = {};
          const { productRounds, ...newObj } = data;
          EXPORT[endpoint] = { id, data: newObj };
          setExport(EXPORT);
        },
        tooltip:
          "공연 등록 및 수정 > 공연 수정 탭에서 IMPORT로 내보낼 수 있습니다.",
      },
      {
        name: "회차 정보 가져오기",
        action: (id) => {
          setTimeout(() => {
            openModal(<GetProductRoundSender id={id} />);
          }, 100);
        },
        tooltip: "공연의 회차 정보를 조회합니다.",
      },
      {
        name: "삭제",
        action: (id) => {
          deleteProducts(id);
        },
        tooltip: "해당 데이터를 제거합니다.",
      },
    ];
    setOptions(OPTIONS);
  }, []);

  return (
    <div id="getProducts">
      {isSuccess && originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey}
          headers={Object.keys(originData[0])}
          queryKey={endpoint} //api endpoint
          innerEndpoint={innerEndpoint}
        />
      )}
    </div>
  );
}
