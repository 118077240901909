function circulate(data, target) {
  let result = {
    ...data,
  };
  //target이 존재하면 배열로 해당 데이터를 배열로 반환 (예 "category"로 들어오면 result에서 category를 배열로 변환)
  if (target) {
    //타겟이 존재하지 않는 경우 리턴
    if (!result[target]) return;
    result = Object.values(result[target]);
  }
  return result;
}

export default class Lang {
  circulate(data, target) {
    let result = {
      ...data,
    };
    //target이 존재하면 배열로 해당 데이터를 배열로 반환 (예 "category"로 들어오면 result에서 category를 배열로 변환)
    if (target) {
      //타겟이 존재하지 않는 경우 리턴
      if (!result[target]) return;
      result = Object.values(result[target]);
    }
    return result;
  }
  static product(target) {
    const category = {
      musical: {
        kr: "뮤지컬",
        en: "musical",
      },
      concert: {
        kr: "콘서트",
        en: "concert",
      },
      classic: {
        kr: "클래식",
        en: "classic",
      },
      dance: {
        kr: "무용",
        en: "dance",
      },
      drama: {
        kr: "연극",
        en: "drama",
      },
      exhibition: {
        kr: "전시회",
        en: "exhibition",
      },
    };
    const temp = {
      category,
    };
    return circulate(temp, target);
  }

  static faq(target) {
    const category = {
      payment: {
        kr: "결제",
        en: "payment",
      },
      ticketing: {
        kr: "예매",
        en: "ticketing",
      },
      etc: {
        kr: "기타",
        en: "etc",
      },
    };
    const temp = {
      category,
    };
    return circulate(temp, target);
  }
}
