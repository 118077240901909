// 에러 발생 시 페이지
function ErrorPage(props) {
  return (
    <div className="sub_section">
      <div
        className="in"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <p> 에러가 발생했습니다. 다시 시도해주세요.</p>
        <button className="bind-click">돌아가기</button>
      </div>
    </div>
  );
}

export default ErrorPage;
