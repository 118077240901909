import { getRefreshToken } from "../../../service/token";
import reqeusts from "../../request";

//사용자 관리 > 마켓 > 클레임 관리 조회
export const getClaimLog = async (id = "") => {
  const data = await reqeusts.olea.get(`claimLogs/${id}`);

  return data;
};

//사용자 관리 > 마켓 > 클레임 관리 조회
export const getClubNftUser = async (id = "") => {
  const data = await reqeusts.olea.get(`clubNfts/${id}`);
  return data;
};
