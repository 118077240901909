import { Button, Paper, TextField, Box } from "@mui/material";
import { useModalStore, useSeatStore } from "../../../../../store/creates";
import "./PostSeat.scss";
import {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import { useCreateSeat } from "../../../../../query/queries/orfeo/venueManage";
import MenuItem from "@mui/material/MenuItem";
import { useGetTheater } from "../../../../../query/queries/orfeo/venueManage";
import { Layer, Rect, Stage, Text } from "react-konva";
import { useGetDetailInfoCall } from "../../../../../query/queries/common";

function seatColor(type, hue = false) {
  if (hue) return "#212121";
  if (type) {
    return "#8FC9C7";
  } else {
    return "#D9D9D9";
  }
}

function cellEvent(index, seat, evt, floor, floors) {
  const newSeat = [...seat];
  let statusBind;
  if (evt.shiftKey) {
    statusBind = false;
  } else {
    statusBind = true;
  }

  newSeat[index].status[floors.indexOf(floor)] = statusBind;
  newSeat[index].floor[floors.indexOf(floor)] = floor;

  return newSeat;
}

function Grid({ x, y }) {
  const { seat, setSeat, floor, linkId, floors } = useSeatStore();
  const [isDrawing, setIsDrawing] = useState(false);
  const requestId = useRef();

  useEffect(() => {
    setSeat(createGrid(x, y));
  }, [x, y]);

  function handleMouseDown(index, evt) {
    const result = cellEvent(index, seat, evt, floor, floors);

    setSeat(result);
    setIsDrawing(true);
  }
  function handleMouseUp() {
    setIsDrawing(false);
  }
  function handleMouseMove(index, evt) {
    if (!isDrawing) return;
    // requestAnimationFrame을 사용하여 드로잉을 최적화
    requestId.current = window.requestAnimationFrame(() => {
      const result = cellEvent(index, seat, evt, floor, floors);
      setSeat(result);
    });
  }

  // 컴포넌트 언마운트 시 requestAnimationFrame 취소
  useEffect(() => {
    return () => {
      if (requestId.current) {
        window.cancelAnimationFrame(requestId.current);
      }
    };
  }, []);

  function createGrid(cols, rows) {
    const newSeat = [];

    const mapLength = cols * rows;
    let x = 0;
    let y = 0;

    for (let index = 0; index < mapLength; index++) {
      if (x > cols - 1) {
        x = 0;
        y = y + 1;
      }
      newSeat[index] = {
        x: x,
        y: y,
        status: [],
        theaterId: linkId,
        floor: [],
      };
      x = x + 1;
    }

    return newSeat;
  }

  return (
    <div className="grid-container">
      <Stage width={50 * x} height={50 * y}>
        <Layer>
          {seat?.map((cell, cellIndex) => (
            <Fragment key={cellIndex}>
              <Rect
                stroke={"black"}
                strokeWidth={0.1}
                x={cell.x * 50}
                y={cell.y * 50}
                width={50}
                height={50}
                fill={seatColor(cell.status[floors.indexOf(floor)])}
                onMouseDown={(e) => handleMouseDown(cellIndex, e.evt)}
                onMouseUp={handleMouseUp}
                onMouseMove={(e) => handleMouseMove(cellIndex, e.evt)}
              />
              <Text
                listening={false}
                text={`(x: ${cell.x})\n(y: ${cell.y})`}
                x={cell.x * 50 + 4}
                y={cell.y * 50 + 4}
              />
            </Fragment>
          ))}
        </Layer>
      </Stage>
    </div>
  );
}

export default function PostSeat() {
  const {
    seat,
    setInfoBind,
    linkId,
    floor,
    setFloor,
    clearSeat,
    floors,
    setFloors,
  } = useSeatStore();
  // const { data: venueData } = useGetVenue();
  const { data: theaterData } = useGetTheater();
  const queryKey = ["theater", "theater-info"];
  const { mutateAsync: getTheaterInfo, data: theaterDetail } =
    useGetDetailInfoCall();
  const { openModal } = useModalStore();
  const { mutateAsync } = useCreateSeat();

  const theaterInfo = useMemo(() => {
    if (!theaterDetail) return;
    return theaterDetail.data;
  }, [theaterDetail]);

  const fields = useMemo(
    () => [
      {
        name: "theaterId",
        type: "text",
        select: true,
        options: theaterData || [],
        required: true,
      },
    ],
    [theaterData]
  );

  useEffect(() => {
    //상영관 조회 후 스토어에 데이터 저장
    if (!theaterInfo) return;
    if (theaterInfo.floorCategory.length > 0) {
      setFloor(theaterInfo.floorCategory[0].id);
      setFloors(theaterInfo.floorCategory);
    }
  }, [theaterInfo]);

  const addSeat = useCallback(() => {
    const filter = seat.filter((s) => s.status.some((s) => s));
    const result = filter.reduce((acc, cur) => {
      cur.floor.forEach((f, i) => {
        const temp = cur.status[i];
        if (temp) {
          const obj = { ...cur, status: true, floorId: f };
          const { floor, ...newObj } = obj;
          acc.push(newObj);
        }
      });

      return acc;
    }, []);

    const payload = {
      data: result,
    };
    console.log(payload);
    // mutateAsync(payload);
  }, [seat]);

  return (
    <div id="postSeat">
      <Formik
        enableReinitialize={true}
        initialValues={{
          theaterId: linkId || "",
        }}
        validationSchema={() => Schema.seat()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          if (theaterInfo.floorCategory.length === 0) {
            openModal(
              "층계 정보가 없어 그릴 수 없습니다. 층계 정보를 먼저 생성해주세요."
            );
            return;
          }
          openModal(
            "그리기 이후 작성한 데이터는 변경 불가합니다. 이대로 정보를 생성 하시겠습니까?",
            () => {
              setInfoBind({
                linkId: values.theaterId,
              });
            }
          );
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  <Field
                    name={f.name}
                    type={f.type}
                    className="formik-input"
                    id={
                      errors[f.name] && touched[f.name] && "error-input-border"
                    }
                  >
                    {({ field, meta }) => (
                      <>
                        <TextField
                          {...field}
                          required={f.required}
                          disabled={linkId || f.disabled ? true : false}
                          type={f.type}
                          select={f.select}
                          sx={{ mb: 1.6, minWidth: 150 }}
                          label={f.name}
                          placeholder={f.placeholder}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={meta.error && meta.touched}
                          helperText={meta.error && meta.touched && meta.error}
                        >
                          {f.options?.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              onClick={(e) => {
                                //상영관 상세 조회
                                getTheaterInfo({
                                  queryKey: queryKey[0],
                                  id: option.id,
                                });
                              }}
                            >
                              [상영관 ID: {option.id}] {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                  </Field>
                </div>
              ))}
              {theaterInfo && (
                <Box sx={{ mb: 1.6 }}>
                  <Box sx={{ mb: 1.6 }}>
                    <TextField disabled value={theaterInfo.x} label={"x"} />
                  </Box>
                  <Box>
                    <TextField disabled value={theaterInfo.y} label={"y"} />
                  </Box>
                </Box>
              )}

              <Box>
                {!linkId && (
                  <Button variant="contained" color="success" type="submit">
                    그리기
                  </Button>
                )}

                {linkId && (
                  <>
                    <Button
                      variant="contained"
                      color="error"
                      type="button"
                      onClick={() => {
                        openModal(
                          "정보를 다시 입력하기 위해 기존 그리기를 취소합니다.",
                          () => clearSeat()
                        );
                      }}
                    >
                      그리기 취소
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ ml: 1 }}
                      type="button"
                      onClick={() => {
                        openModal(`좌석을 추가 하시겠습니까?`, () => addSeat());
                      }}
                    >
                      좌석 정보 저장
                    </Button>
                  </>
                )}
              </Box>

              {linkId && (
                <>
                  <TextField
                    select
                    sx={{ mt: 1.6, mb: 1.6, minWidth: 150 }}
                    value={floor}
                    label={"FLOOR"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setFloor(e.target.value);
                    }}
                  >
                    {floors.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Grid x={theaterInfo.x} y={theaterInfo.y} />
                </>
              )}
            </Paper>
          </Form>
        )}
      </Formik>
    </div>
  );
}
