import reqeusts from "../../request";

//고객센터 > 공지 제거
export const deleteAnnouncements = async (id) => {
  const data = await reqeusts.orfeo.delete(`announcements/${id}`);
  return data;
};

//고객센터 > 이벤트 제거
export const deleteEvents = async (id) => {
  const data = await reqeusts.orfeo.delete(`events/${id}`);
  return data;
};

//고객센터 > faq 제거
export const deleteFaq = async (id) => {
  const data = await reqeusts.orfeo.delete(`faq/${id}`);
  return data;
};

//공연 관리 > 공연 > 공연 제거
export const deleteProducts = async (id) => {
  const data = await reqeusts.orfeo.delete(`products/${id}`);
  return data;
};

//공연 관리 > 공연 > 공연 회차 제거
export const deleteProductRound = async (id) => {
  const data = await reqeusts.orfeo.delete(`product-round/${id}`);
  return data;
};

//극장 > 극장 제거
export const deleteVenue = async (id) => {
  const data = await reqeusts.orfeo.delete(`venue/${id}`);
  return data;
};

//극장 > 상영관 제거
export const deleteTheater = async (id) => {
  const data = await reqeusts.orfeo.delete(`theater/${id}`);
  return data;
};

//극장 > 상영관 층계 제거
export const deleteFloor = async (id) => {
  const data = await reqeusts.orfeo.delete(`floor-category/${id}`);
  return data;
};

//극장 > 좌석 제거
export const deleteTheaterInfo = async (id) => {
  const data = await reqeusts.orfeo.delete(`theater-info/${id}`);
  return data;
};
