import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createNotice } from "../../apis/calliope/POST";
import { onErrorBind } from "../common";
import { getNotice } from "../../apis/calliope/GET";
import { updateNotice } from "../../apis/calliope/UPDATE";
import { deleteNotice } from "../../apis/calliope/DELETE";

export function useCreateNotice() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createNotice(payload), {
    onSuccess: async () => {
      const { data: noticeData } = await getNotice();
      queryClient.setQueryData(["get", "notice/"], noticeData);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useUpdateNotice() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateNotice(req), {
    onSuccess: async (data, { id }) => {
      const { data: noticeData } = await getNotice();
      queryClient.setQueryData(["get", "notice/"], noticeData);
      const { data: noticeDetail } = await getNotice(id);
      queryClient.setQueryData(["get", `notice/${id}`], noticeDetail);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetNotice() {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["get", `notice/`],
    queryFn: async () => {
      const cachedData = queryClient.getQueryData(["get", `notice/`]);
      if (cachedData) {
        return cachedData;
      }
      const { data } = await getNotice();

      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useDeleteNotice() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteNotice(id), {
    onSuccess: async () => {
      const { data: noticeData } = await getNotice();
      queryClient.setQueryData(["get", "notice/"], noticeData);
    },
    onError: (e) => {
      onErrorBind(e);
    },
    onSettled: ({ ...p }) => {
      console.log(p);
    },
  });
}
