import CollapseContainer from "./CollapseContainer";
import { Typography } from "@mui/material";
import { useState } from "react";
import Divider from "@mui/material/Divider";

//넘겨받은 데이터 객체 프리뷰 (페이로드 확인)
export default function CodeSample({ sample }) {
  const [open, isOpen] = useState(true);

  return (
    <div className="sample">
      <Typography
        variant="h7"
        className={`btn_toggle${open ? " on" : ""}`}
        onClick={(e) => {
          isOpen(!open);
        }}
      >
        CODE
      </Typography>
      <CollapseContainer
        isOpen={open}
        content={
          <div>
            <pre>
              <code>{JSON.stringify(sample, null, 2)}</code>
            </pre>
          </div>
        }
      ></CollapseContainer>
      <Divider />
    </div>
  );
}
