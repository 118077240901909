import axios from "axios";
import reqeusts from "../../request";

//고객센터 > 공지 > 공지 조회
export const getAnnouncements = async (id = "") => {
  const data = await reqeusts.orfeo.get(`announcements/${id}`);
  return data;
};

//고객센터 > 이벤트 > 이벤트 조회
export const getEvents = async (id = "") => {
  const data = await reqeusts.orfeo.get(`events/${id}`);
  return data;
};
//고객센터 > FAQ > FAQ 조회
export const getFaq = async (id = "") => {
  const data = await reqeusts.orfeo.get(`faq/${id}`);
  return data;
};

//공연 관리 > 공연 > 공연 조회
export const getProducts = async (id = "") => {
  const data = await reqeusts.orfeo.get(`products/${id}`);
  return data;
};

//공연 관리 > 공연 > 공연 회차 조회
export const getProductRound = async (params) => {
  const data = await reqeusts.orfeo.get(`product-round/`, {
    params,
  });
  return data;
};

//공연 관리 > 공연 > 공연 회차> 통합 조회
export const getProductRoundTotal = async (params) => {
  const data = await reqeusts.orfeo.get(`product-round/total`, {
    params,
  });
  return data;
};

//극장 관리 > 극장 조회
export const getVenue = async (id = "") => {
  const data = await reqeusts.orfeo.get(`venue/${id}`);
  return data;
};

//극장 관리 > 상영관 조회
export const getTheater = async (id = "") => {
  const data = await reqeusts.orfeo.get(`theater/${id}`);
  return data;
};

//극장 관리 > 상영관 층계 조회
export const getFloor = async (params) => {
  const data = await reqeusts.orfeo.get(`floor-category/`, {
    params,
  });
  return data;
};

//극장 관리 > 좌석 조회
export const getTheaterInfo = async (id = "") => {
  const data = await reqeusts.orfeo.get(`theater-info/${id}`);
  return data;
};
