import { useEffect, useMemo } from "react";
import { useGetClubNftUser } from "../../../../../../../query/queries/olea/users";
import MuiTable from "../../../../../../common/MuiTable";
import { useOptionsStore } from "../../../../../../../store/creates";
import mergeIdMap from "../../../../../../../utils/mapping";

export function ClubRequest() {
  // GET
  const uniKey = "clubNftsId";
  const { data: clubNftUser, isSuccess } = useGetClubNftUser();
  const originData = useMemo(() => clubNftUser || [], [clubNftUser]);
  const { setOptions, options } = useOptionsStore();

  useEffect(() => {
    if (options[uniKey]) return;
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "승인",
        action: (id, data) => {
          console.log(id);
          console.log(data);
        },
      },
    ];
    setOptions(OPTIONS);
  }, []);

  return (
    <div id="ClaimManage">
      {isSuccess && originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey} //get data key
          headers={Object.keys(originData[0])}
          // queryKey="clubNfts" //api endpoint
        />
      )}
    </div>
  );
}
