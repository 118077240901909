import { Button, Paper, TextField, Typography } from "@mui/material";
import { useModalStore } from "../../../../../store/creates";
import { useCallback, useEffect, useMemo } from "react";
import {
  useGetProductRound,
  useGetProductRoundTotal,
} from "../../../../../query/queries/orfeo/productsManage";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import MenuItem from "@mui/material/MenuItem";
import CodeSample from "../../../../../utils/CodeSample";
import MuiDateField from "../../../../common/MuiDateField";
import dayjs from "dayjs";

const fields = [
  {
    name: "productId",
    type: "text",
    required: true,
    disabled: true,
  },
];

export default function GetProductRoundSender({ id }) {
  const { mutateAsync: getRounds } = useGetProductRound();
  const { mutateAsync: getTotalRounds } = useGetProductRoundTotal();
  const { openModal } = useModalStore();

  const getProductRound = useCallback((values) => {
    const { selectedDate } = values;
    const productRound = {
      productId: id,
    };
    if (selectedDate && selectedDate !== "Invalid Date") {
      productRound.selectedDate = selectedDate;
      getTotalRounds(productRound);
    } else {
      getRounds(productRound);
    }
  }, []);

  return (
    <div id="getProductRound">
      <Formik
        enableReinitialize={true}
        initialValues={{
          productId: id,
          selectedDate: dayjs().format("YYYY-MM-DD"),
        }}
        validationSchema={() => Schema.productRoundBefore()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          let message = "입력 날짜로 공연 회차 정보를 조회 하시겠습니까?";
          if (!values.selectedDate) {
            //날짜 입력 안했을 때
            message = "공연의 모든 회차 정보를 조회합니다. 조회 하시겠습니까?";
          }
          openModal(message, () => getProductRound(values));
        }}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form>
            <CodeSample sample={values} />
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  <Field
                    name={f.name}
                    type={f.type}
                    className="formik-input"
                    id={
                      errors[f.name] && touched[f.name] && "error-input-border"
                    }
                  >
                    {({ field, meta }) => (
                      <>
                        <TextField
                          {...field}
                          required={f.required}
                          type={f.type}
                          select={f.select}
                          sx={{ mb: 1.6, minWidth: 150 }}
                          label={f.name}
                          disabled={f.disabled}
                          placeholder={f.placeholder}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={meta.error && meta.touched}
                          helperText={meta.error && meta.touched && meta.error}
                        >
                          {f.options?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              [공연 ID: {option.id}] {option.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                  </Field>
                </div>
              ))}

              <div className="rel">
                <MuiDateField
                  fieldValue={values.selectedDate}
                  name={"selectedDate"}
                  setFieldValue={setFieldValue}
                />
                <Typography variant="span" color="inherit">
                  날짜 미입력 시 해당 공연의 모든 회차 정보를 조회합니다.
                </Typography>
              </div>
            </Paper>

            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              공연 회차 정보 조회
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
