import Collapse from "@kunukn/react-collapse";

//컨텐츠(컴포넌트)를 isOpen 컨트롤러로 제어할 수 있는 Collapse
export default function CollapseContainer({ content, isOpen }) {
  return (
    <Collapse addState isOpen={isOpen}>
      {content}
    </Collapse>
  );
}

//배열로 된 isOpen을 멀티 컨트롤 하기 위한 함수 HorizontalTabs, VerticalTabs 컴포넌트에서 사용 중
export function collapseToggle(isCollapse, i) {
  const some = isCollapse.some((data) => data === i);

  if (some) {
    const filter = isCollapse.filter((data) => data !== i);
    return filter;
  } else return [...isCollapse, i];
}
