import { useMemo, useEffect } from "react";
import { useExportStore, useOptionsStore } from "../../../../../store/creates";
import MuiTable from "../../../../common/MuiTable";
import { useDeleteProductRound } from "../../../../../query/queries/orfeo/productsManage";
import mergeIdMap from "../../../../../utils/mapping";
import Paper from "@mui/material/Paper";

export default function GetProductRound({ data }) {
  const endpoint = "product-round";
  const uniKey = "productRoundId";
  const { mutateAsync: deleteRound } = useDeleteProductRound();
  const originData = useMemo(() => mergeIdMap(uniKey, data || []), [data]);
  const { setOptions, options } = useOptionsStore();
  const { setExport } = useExportStore();

  const deleteProductRound = (id) => {
    deleteRound(id);
  };

  useEffect(() => {
    if (options[uniKey]) return;
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "EXPORT",
        action: (id, data) => {
          const EXPORT = {};
          EXPORT[endpoint] = { id, data };
          setExport(EXPORT);
        },
        tooltip:
          "공연 등록 및 수정 > 공연 회차 수정 탭에서 IMPORT로 내보낼 수 있습니다.",
      },
      {
        name: "삭제",
        action: (id) => {
          deleteProductRound(id);
        },
        tooltip: "해당 데이터를 제거합니다.",
      },
    ];
    setOptions(OPTIONS);
  }, []);

  return (
    <Paper sx={{ p: 1, background: "#ede7f6" }}>
      {originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey}
          headers={Object.keys(originData[0])}
          queryKey={endpoint} //api endpoint
        />
      )}
    </Paper>
  );
}
