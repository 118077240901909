import useClasses from "./useClasses";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { useMenuStore } from "../../store/creates";
import isEmpty from "../../utils/validate/empty";
import PostAnnouncements from "../menu/orfeo/customerService/Announcements/PostAnnouncements";
import UpdateAnnouncements from "../menu/orfeo/customerService/Announcements/UpdateAnnouncements";
import PostEvents from "../menu/orfeo/customerService/Events/PostEvents";
import UpdateEvents from "../menu/orfeo/customerService/Events/UpdateEvents";
import PostFaq from "../menu/orfeo/customerService/Faq/PostFaq";
import UpdateFaq from "../menu/orfeo/customerService/Faq/UpdateFaq";
import { ClubAdd } from "../menu/olea/userManage/market/userTypeChange/club/ClubAdd";
import { ClubRequest } from "../menu/olea/userManage/market/userTypeChange/club/ClubRequest";
import PostNotice from "../menu/calliope/CustomerService/Notice/PostNotice";
import UpdateNotice from "../menu/calliope/CustomerService/Notice/UpdateNotice";
import PostVenue from "../menu/orfeo/venueManage/Venue/PostVenue";
import UpdateVenue from "../menu/orfeo/venueManage/Venue/UpdateVenue";
import PostTheater from "../menu/orfeo/venueManage/Theater/PostTheater";
import UpdateTheater from "../menu/orfeo/venueManage/Theater/UpdateTheater";
import PostProducts from "../menu/orfeo/productsManage/Products/PostProducts";
import UpdateProducts from "../menu/orfeo/productsManage/Products/UpdateProducts";
import PostProductRound from "../menu/orfeo/productsManage/ProductRound/PostProductsRound";
import UpdateProductRound from "../menu/orfeo/productsManage/ProductRound/UpdateProductRound";
import PostFloor from "../menu/orfeo/venueManage/Theater/Floor/PostFloor";
import UpdateFloor from "../menu/orfeo/venueManage/Theater/Floor/UpdateFloor";
import GetFloor from "../menu/orfeo/venueManage/Theater/Floor/GetFloor";
import VerticalTabs from "../pages/ctr/VerticalTabs";

const styles = (theme) => ({
  box: {
    background: "#ede7f6",
    borderRadius: 3,
    border: 0,
    minHeight: 48,
    padding: "8px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .15)",
  },
  label: {
    fontFamily: "'Noto Sans KR', sans-serif",
    fontSize: 16,
  },
});

/**
 * @typedef {Object} Menus
 * @property {any[]} olea_userManage_userTypeChange
 * @property {any[]} calliope_customerService_notice
 * @property {any[]} orfeo_customerService_announcements
 * @property {any[]} orfeo_customerService_events
 * @property {any[]} orfeo_customerService_faq
 * @property {any[]} orfeo_venueManage_venue
 * @property {any[]} orfeo_venueManage_theater
 * @property {any[]} orfeo_venueManage_theater_floor
 * @property {any[]} orfeo_productsManage_products
 */

/**
 * @typedef {Object} HorizontalDatas;
 * @property {Menus} horizontalDatas
 * @property {any} isCollapse
 * @property {any} setIsCollapse
 * @property {any} classes
 */

/**
 * @returns {HorizontalDatas}
 */
export const useHorizontal = () => {
  const classes = useClasses(styles);
  const [isCollapse, setIsCollapse] = useState([0, 1, 2, 3]);
  const { verticalDatas, horizontalDatas, setHorizontal } = useMenuStore();

  console.log(verticalDatas);

  useEffect(() => {
    //olea
    if (!isEmpty(horizontalDatas)) return;
    const olea_userManage_userTypeChange = {
      ctrDeps: ["클럽 등록", "스타터 등록"],
      details: [
        [
          {
            label: "club 등록 요청 조회/승인",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ClubRequest />
                </Box>
              </Box>
            ),
          },
          {
            label: "club nft 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ClubAdd />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "스타터 등록 요청 조회/승인",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "스타터 nft 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const calliope_customerService_notice = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "공지 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostNotice />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "공지 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateNotice />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService_announcements = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "공지 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostAnnouncements />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "공지 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateAnnouncements />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService_events = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "이벤트 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostEvents />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "이벤트 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateEvents />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService_faq = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "FAQ 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostFaq />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "FAQ 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateFaq />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_venueManage_venue = {
      ctrDeps: ["등록", "수정"],
      details: [
        [
          {
            label: "극장 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostVenue />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "극장 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateVenue />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const orfeo_venueManage_theater = {
      ctrDeps: ["상영관 등록", "상영관 수정", "층계 등록", "층계 수정"],
      details: [
        [
          {
            label: "상영관 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostTheater />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "상영관 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateTheater />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "층계 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostFloor />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "층계 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateFloor />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const orfeo_venueManage_theater_floor = {
      ctrDeps: ["조회", "등록", "수정"],
      details: [
        [
          {
            label: "층계 조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetFloor />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "층계 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostFloor />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "층계 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateFloor />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const orfeo_productsManage_products = {
      ctrDeps: ["공연 등록", "공연 수정", "공연 회차 등록", "공연 회차 수정"],
      details: [
        [
          {
            label: "공연 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostProducts />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "공연 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateProducts />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "회차 정보 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostProductRound />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "회차 정보 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UpdateProductRound />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const menus = {
      olea_userManage_userTypeChange,
      calliope_customerService_notice,
      orfeo_customerService_announcements,
      orfeo_customerService_events,
      orfeo_customerService_faq,
      orfeo_venueManage_venue,
      orfeo_venueManage_theater,
      orfeo_venueManage_theater_floor,
      orfeo_productsManage_products,
    };
    setHorizontal(menus);
  }, []);

  return { horizontalDatas, isCollapse, setIsCollapse, classes };
};
