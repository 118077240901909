import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

//토큰 스토어
export const useTokenStore = create((set, get) => ({
  token: { oleaAccessToken: null, oleaRefreshToken: null },
  setToken: (tokenData) => set({ token: { ...get().token, ...tokenData } }),
  clearOleaToken: () =>
    set({
      token: { ...get().token, oleaAccessToken: null, oleaRefreshToken: null },
    }),
  clearOrfeoToken: () =>
    set({
      token: {
        ...get().token,
        orfeoAccessToken: null,
        orfeoRefreshToken: null,
      },
    }),

  clearCalliopeToken: () =>
    set({
      token: {
        ...get().token,
        calliopeAccessToken: null,
        calliopeRefreshToken: null,
      },
    }),
}));

// 모달 스토어
export const useModalStore = create((set) => ({
  isModalOpen: false,
  message: "",
  onConfirm: () => {},
  openModal: (message, onConfirm) =>
    set({ isModalOpen: true, message, onConfirm }),
  closeModal: () =>
    set({ isModalOpen: false, message: "", onConfirm: () => {} }),
}));

// 데이터 Export 스토어
export const useExportStore = create((set) => ({
  exportData: {},
  setExport: (data) => set({ exportData: data }),
  clearExport: () => set({ exportData: {} }),
}));

// 셀렉트 옵션 스토어
export const useOptionsStore = create((set) => ({
  options: {},
  setOptions: (options) => set({ options }),
  clearOptions: () => set({ options: [] }),
}));

// 탭 or 라우트 리빙 가드 스토어
export const useLeavingGuardStore = create((set) => ({
  isGuard: false,
  setIsGuard: (isGuard) => set({ isGuard: isGuard }),
  clearIsGuard: () => set({ isGuard: false }),
}));

// 라우팅 추적 스토어
export const useRouteFollowerStore = create((set) => ({
  pageType: "",
  verticalValue: null,
  horizontalValue: null,
  setFollower: (target) => set({ pageType: target }),
  setTabIndex: (data) => set({ ...data }),
  clearFollower: () => set({ pageType: "" }),
  clearVertical: () => set({ verticalValue: null }),
  clearHorizontal: () => set({ horizontalValue: null }),
}));

// 메뉴 저장 스토어
export const useMenuStore = create((set) => ({
  verticalDatas: {},
  horizontalDatas: {},
  setVertical: (v) => set({ verticalDatas: v }),
  setHorizontal: (h) => set({ horizontalDatas: h }),
}));

//즐겨찾기 스토어
export const useFavoriteStore = create()(
  persist(
    (set) => ({
      favorite: [],
      setFavorite: (data) =>
        set({
          favorite: data,
        }),
    }),
    {
      name: "favoriteStore",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 좌석 정보(theart-info) 생성 관련 스토어
export const useSeatStore = create((set) => ({
  linkId: null,
  seat: [],
  floor: null,
  floors: [], //일단 정적 데이터
  setSeat: (seat) => set({ seat }),
  setInfoBind: (data) => set({ linkId: data.linkId }),
  setFloor: (floor) => set({ floor: floor }),
  setFloors: (floors) => set({ floors: floors }),
  clearSeat: () => set({ linkId: null, seat: [], floor: "1층" }),
}));

export const useTableStore = create((set) => ({
  tables: [],
  setTables: (tables) => set({ tables }),
  clearTables: () =>
    set({
      tables: [],
    }),
}));

// 데이터 생성 스토어 (안쓰는 중이며 불필요 시 제거 예정)
export const useFactoryStore = create((set, get) => ({
  //공연 회차 정보 조회
  productRound: {
    productId: "", //공연 ID
    selectedDate: null, //조회할 날짜
  },
  //공연 캐스팅 생성
  cast: {
    name: "", //배우 이름
    casting: "", //캐스팅 배역 이름
    productId: "", //공연 ID
    productRoundId: "", //공연 회차정보 ID
  },
  //상영관 층계 생성
  floorCategory: {
    name: "", //상영관 층계 정보 이름 (1층, 2층)
    idx: "", //층계 정보 별 순서
    theaterId: "", //층계 정보가 적용되는 상영관 ID
  },
  setFactory: (data) => set({ ...get(), ...data }),
}));
