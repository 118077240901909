import { useMemo, useEffect } from "react";
import {
  useExportStore,
  useModalStore,
  useOptionsStore,
} from "../../../../../store/creates";
import MuiTable from "../../../../common/MuiTable";
import {
  useDeleteTheater,
  useGetFloor,
  useGetTheater,
} from "../../../../../query/queries/orfeo/venueManage";
import mergeIdMap from "../../../../../utils/mapping";
import PostFloor from "./Floor/PostFloor";
import HorizontalTabs from "../../../../pages/ctr/HorizontalTabs";
import { useHorizontal } from "../../../../hooks/useHorizontal";

export default function GetTheater() {
  const endpoint = "theater";
  const uniKey = "theaterId";
  const { data: resData, isSuccess } = useGetTheater();
  const { mutateAsync: getFloor } = useGetFloor();
  const { openModal } = useModalStore();
  const { mutateAsync } = useDeleteTheater();
  const originData = useMemo(
    () => mergeIdMap(uniKey, resData || []),
    [resData]
  );
  const { setOptions, options } = useOptionsStore();
  const { setExport } = useExportStore();
  // const { horizontalDatas } = useHorizontal();
  const innerEndpoint = ["floor-category"];

  const deleteTheater = (id) => {
    mutateAsync(id);
  };

  useEffect(() => {
    if (options[uniKey]) return;
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "EXPORT",
        action: (id, data) => {
          const EXPORT = {};
          EXPORT[endpoint] = { id, data };
          setExport(EXPORT);
        },
        tooltip:
          "상영관 등록 및 수정 > 수정 탭에서 IMPORT로 내보낼 수 있습니다.",
      },
      {
        name: "층계 관리",
        action: (id) => {
          const params = {
            theaterId: id,
          };
          getFloor(params);
        },
        tooltip: "상영관 층계 정보를 관리합니다.",
      },
      {
        name: "삭제",
        action: (id) => {
          deleteTheater(id);
        },
        tooltip: "해당 데이터를 제거합니다.",
      },
    ];
    setOptions(OPTIONS);
  }, []);

  return (
    <div id="getTheater">
      {isSuccess && originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey}
          headers={Object.keys(originData[0])}
          queryKey={endpoint} //api endpoint
          innerEndpoint={innerEndpoint}
        />
      )}
    </div>
  );
}
