import { useEffect, useMemo } from "react";
import "./ClaimManage.scss";
import MuiTable from "../../../../common/MuiTable";
import { useModalStore, useOptionsStore } from "../../../../../store/creates";
import {
  useGetClaimLog,
  useUpdateClaimLog,
} from "../../../../../query/queries/olea/market";
import { ethers } from "ethers";
import isEmpty from "../../../../../utils/validate/empty";
import mergeIdMap from "../../../../../utils/mapping";

const tokenABI = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function decimals() view returns (uint8)",
  "function balanceOf(address) view returns (uint)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address to, uint amount)",
];

export default function ClaimManage() {
  // GET
  const uniKey = "claimLogsId";
  const { data: claimLog, isSuccess } = useGetClaimLog();
  const originData = useMemo(() => claimLog || [], [claimLog]);
  const { setOptions, options } = useOptionsStore();
  const { openModal } = useModalStore();
  const { mutateAsync } = useUpdateClaimLog();

  async function transferAction(data) {
    try {
      let signer = null;
      let EtherToWei;
      let tx;
      let provider;

      if (window.ethereum == null) {
        provider = ethers.getDefaultProvider();
      } else {
        provider = new ethers.BrowserProvider(window.ethereum);
        signer = await provider.getSigner();
      }
      const contractInfo = new ethers.Contract(
        process.env.REACT_APP_TOKEN_ADDRESS, //from 주소
        tokenABI,
        provider
      );

      const address = await signer.getAddress();
      // Wei 단위로 변경하여 최종적으로 보낼 OLEA 값을 산출하여
      EtherToWei = ethers.parseUnits(`${data.amount}`, "ether");

      // 연결된 signer가 securistAddress로 EtherToWei만큼의 OLEA를 이체한다.
      tx = await contractInfo
        .connect(signer)
        .transfer(data.address, EtherToWei); //to 주소

      // 만약 사용자가 transfer를 기다린 후 receipt를 받았다면,
      const receipt = await tx.wait();

      if (receipt.status === 0) openModal("receipt status 0", false);
      if (receipt.status === 1) {
        const payload = { claimState: 1, claimLogsId: data.claimLogsId };
        mutateAsync(payload);
      }
    } catch ({ ...err }) {
      openModal(err.reason, false);
    }
  }

  useEffect(() => {
    if (options[uniKey]) return;
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "이체",
        action: (id, data) => {
          if (data.claimState !== 0) {
            openModal(0);
            return;
          }
          transferAction(data);
        },
      },
    ];
    setOptions(OPTIONS);
  }, []);

  return (
    <div id="ClaimManage">
      {isSuccess && originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey}
          headers={Object.keys(originData[0])}
          queryKey="claimLogs" //api endpoint
        />
      )}
    </div>
  );
}
