import CollapseContainer from "../../utils/CollapseContainer";
import useClasses from "./useClasses";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useState, useEffect } from "react";
import ClaimManage from "../menu/olea/userManage/market/ClaimManage";
import UserTypeChange from "../menu/olea/userManage/market/userTypeChange/UserTypeChange";
import GetAnnouncements from "../menu/orfeo/customerService/Announcements/GetAnnouncements.jsx";
import GetEvents from "../menu/orfeo/customerService/Events/GetEvents.jsx";
import GetFaq from "../menu/orfeo/customerService/Faq/GetFaq.jsx";
import HorizontalTabs from "../pages/ctr/HorizontalTabs";
import GetNotice from "../menu/calliope/CustomerService/Notice/GetNotice";
import { useMenuStore } from "../../store/creates";
import isEmpty from "../../utils/validate/empty";
import { useHorizontal } from "./useHorizontal";
import OLEA_Signin from "../menu/etc/Signin/OLEA_Signin";
import OLEA_Signout from "../menu/etc/Signout/OLEA_Signout";
import ORFEO_Signin from "../menu/etc/Signin/ORFEO_Signin";
import ORFEO_Signout from "../menu/etc/Signout/ORFEO_Signout";
import GetVenue from "../menu/orfeo/venueManage/Venue/GetVenue";
import GetTheater from "../menu/orfeo/venueManage/Theater/GetTheater";
import GetProducts from "../menu/orfeo/productsManage/Products/GetProducts";
import PostSeat from "../menu/orfeo/seatManage/Seat/PostSeat";

const styles = () => ({
  box: {
    overflow: "hidden",
    background: "#eee",
    borderRadius: 3,
    border: 0,
    minHeight: 48,
    padding: 8,
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .15)",
  },
  label: {
    fontFamily: "'Noto Sans KR', sans-serif",
    fontSize: 16,
  },
});

/**
 * @typedef {Object} Menus
 * @property {any[]} signin_manage
 * @property {any[]} olea_userManage
 * @property {any[]} olea_marketManage
 * @property {any[]} olea_goodsManage
 * @property {any[]} orfeo_userManage
 * @property {any[]} orfeo_venueManage
 * @property {any[]} manager_orfeo_venueManage
 * @property {any[]} orfeo_productsManage
 * @property {any[]} orfeo_customerService
 * @property {any[]} calliope_customerService
 */

/**
 * @typedef {Object} VerticalDatas;
 * @property {Menus} verticalDatas
 * @property {any} isCollapse
 * @property {any} setIsCollapse
 * @property {any} classes
 */

/**
 * @returns {VerticalDatas}
 */
export const useVertical = () => {
  const classes = useClasses(styles);
  const [isCollapse, setIsCollapse] = useState([0, 1, 2, 3]);
  const { verticalDatas, setVertical } = useMenuStore();
  const { horizontalDatas } = useHorizontal();

  useEffect(() => {
    if (isEmpty(horizontalDatas)) return;
    if (!isEmpty(verticalDatas)) return;

    //signin
    const signin_manage = {
      ctrDeps: ["OLEA", "ORFEO", "CALLIOPE"],
      details: [
        [
          {
            label: "OLEA 로그인",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <OLEA_Signin />
                </Box>
              </Box>
            ),
          },
          {
            label: "OLEA 로그아웃",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <OLEA_Signout />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "ORFEO 로그인",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ORFEO_Signin />
                </Box>
              </Box>
            ),
          },
          {
            label: "ORFEO 로그아웃",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ORFEO_Signout />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "CALLIOPE 로그인",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    //olea
    const olea_userManage = {
      ctrDeps: ["월렛", "마켓"],
      details: [
        [
          {
            label: "입고 요청 조회/승인",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}></Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 0) || false}
              />
            ),
          },
          {
            label: "입고 요청 등록",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}></Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 1) || false}
              />
            ),
          },
          {
            label: "클레임 요청 조회/승인",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}></Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 2) || false}
              />
            ),
          },
          {
            label: "클레임 요청 전송",
            component: (
              <CollapseContainer
                content={
                  <Box sx={{ p: 1 }}>
                    <Box className={clsx(classes.box)}></Box>
                  </Box>
                }
                isOpen={isCollapse.some((data) => data === 3) || false}
              />
            ),
          },
        ],
        [
          {
            label: "사용자 타입 변경",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <UserTypeChange />
                </Box>
              </Box>
            ),
          },
          {
            label: "경고 처리",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "블랙리스트 관리",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "클레임 관리",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <ClaimManage />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const olea_marketManage = {
      ctrDeps: ["holder", "ticket", "club"],
      details: [
        [
          {
            label: "카테고리 범주 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "민팅 이벤트",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "민팅 이벤트",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "카테고리 범주 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const olea_goodsManage = {
      ctrDeps: ["굿즈", "일반", "nft"],
      details: [
        [
          {
            label: "미정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "제거",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "제거",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    //orfeo
    const orfeo_venueManage = {
      ctrDeps: ["극장", "상영관", "좌석"],
      details: [
        [
          {
            label: "극장 조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetVenue />
                </Box>
              </Box>
            ),
          },
          {
            label: "극장 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs
                    idx={horizontalDatas.orfeo_venueManage_venue}
                  />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "상영관 조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetTheater />
                </Box>
              </Box>
            ),
          },
          {
            label: "상영관 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs
                    idx={horizontalDatas.orfeo_venueManage_theater}
                  />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "좌석 추가",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <PostSeat />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const manager_orfeo_venueManage = {
      ctrDeps: ["유저"],
      details: [
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };

    const orfeo_userManage = {
      ctrDeps: ["유저"],
      details: [
        [
          {
            label: "조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_customerService = {
      ctrDeps: ["공지", "이벤트", "FAQ"],
      details: [
        [
          {
            label: "공지 조회 및 삭제",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetAnnouncements />
                </Box>
              </Box>
            ),
          },
          {
            label: "공지 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs
                    idx={horizontalDatas.orfeo_customerService_announcements}
                  />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "이벤트 조회 및 삭제",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetEvents />
                </Box>
              </Box>
            ),
          },
          {
            label: "이벤트 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs
                    idx={horizontalDatas.orfeo_customerService_events}
                  />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "FAQ 조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetFaq />
                </Box>
              </Box>
            ),
          },
          {
            label: "FAQ 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs
                    idx={horizontalDatas.orfeo_customerService_faq}
                  />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const orfeo_productsManage = {
      ctrDeps: ["공연", "티켓"],
      details: [
        [
          {
            label: "공연 조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetProducts />
                </Box>
              </Box>
            ),
          },
          {
            label: "공연 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs
                    idx={horizontalDatas.orfeo_productsManage_products}
                  />
                </Box>
              </Box>
            ),
          },
        ],
        [
          {
            label: "티켓 조회",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
          {
            label: "티켓 등록",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}></Box>
              </Box>
            ),
          },
        ],
      ],
    };
    //calliope
    const calliope_customerService = {
      ctrDeps: ["공지"],
      details: [
        [
          {
            label: "공지 조회 및 삭제",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <GetNotice />
                </Box>
              </Box>
            ),
          },
          {
            label: "공지 등록 및 수정",
            component: (
              <Box sx={{ p: 1 }}>
                <Box className={clsx(classes.box)}>
                  <HorizontalTabs
                    idx={horizontalDatas.calliope_customerService_notice}
                  />
                </Box>
              </Box>
            ),
          },
        ],
      ],
    };
    const menus = {
      signin_manage,
      olea_userManage,
      olea_marketManage,
      olea_goodsManage,
      orfeo_userManage,
      orfeo_venueManage,
      orfeo_productsManage,
      orfeo_customerService,
      calliope_customerService,
      manager_orfeo_venueManage,
    };
    setVertical(menus);
  }, [horizontalDatas]);

  return { verticalDatas, isCollapse, setIsCollapse, classes };
};
