import { useMemo, useEffect } from "react";
import {
  useExportStore,
  useModalStore,
  useOptionsStore,
} from "../../../../../store/creates";
import MuiTable from "../../../../common/MuiTable";
import {
  useDeleteNotice,
  useGetNotice,
} from "../../../../../query/queries/calliope/customerService";
import mergeIdMap from "../../../../../utils/mapping";

export default function GetNotice() {
  const endpoint = "notice";
  const uniKey = "noticeId";
  const { data: resData, isSuccess } = useGetNotice();
  const { mutateAsync } = useDeleteNotice();
  const originData = useMemo(
    () => mergeIdMap(uniKey, resData || []),
    [resData]
  );
  const { setOptions, options } = useOptionsStore();
  const { setExport } = useExportStore();

  const deleteNotice = (id) => {
    mutateAsync(id);
  };

  useEffect(() => {
    if (options[uniKey]) return;
    const OPTIONS = { ...options };
    OPTIONS[uniKey] = [
      {
        name: "EXPORT",
        action: (id, data) => {
          const EXPORT = {};
          EXPORT[endpoint] = { id, data };
          setExport(EXPORT);
        },
        tooltip: "공지 등록 및 수정 > 수정 탭에서 IMPORT로 내보낼 수 있습니다.",
      },
      {
        name: "삭제",
        action: (id) => {
          deleteNotice(id);
        },
        tooltip: "해당 데이터를 제거합니다.",
      },
    ];
    setOptions(OPTIONS);
  }, []);

  return (
    <div id="getNotice">
      {isSuccess && originData.length > 0 && (
        <MuiTable
          rows={originData}
          uniKey={uniKey}
          headers={Object.keys(originData[0])}
          queryKey={endpoint} //api endpoint
        />
      )}
    </div>
  );
}
