import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createFloor,
  createTheater,
  createTheaterInfo,
  createVenue,
} from "../../apis/orfeo/POST";
import { onErrorBind } from "../common";
import {
  getFloor,
  getTheater,
  getTheaterInfo,
  getVenue,
} from "../../apis/orfeo/GET";
import {
  updateFloor,
  updateTheater,
  updateTheaterInfo,
  updateVenue,
} from "../../apis/orfeo/UPDATE";
import {
  deleteFloor,
  deleteTheater,
  deleteTheaterInfo,
  deleteVenue,
} from "../../apis/orfeo/DELETE";
import { useModalStore, useTableStore } from "../../../store/creates";
import { tableDepsMap } from "../../../utils/mapping";
import GetFloor from "../../../components/menu/orfeo/venueManage/Theater/Floor/GetFloor";

//극장
export function useCreateVenue() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createVenue(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "venue/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetVenue() {
  return useQuery({
    queryKey: ["get", `venue/`],
    queryFn: async () => {
      const { data } = await getVenue();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateVenue() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateVenue(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "venue/"]);
      queryClient.invalidateQueries(["get", `venue/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteVenue() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteVenue(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "venue/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

//상영관
export function useCreateTheater() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createTheater(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "theater/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetTheater() {
  return useQuery({
    queryKey: ["get", `theater/`],
    queryFn: async () => {
      const { data } = await getTheater();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateTheater() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateTheater(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "theater/"]);
      queryClient.invalidateQueries(["get", `theater/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteTheater() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteTheater(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "theater/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

//상영관 층계
export function useCreateFloor() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createFloor(payload), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["get", `floor-category/`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetFloor() {
  const queryClient = useQueryClient();
  const { tables, setTables } = useTableStore();
  const { openModal } = useModalStore();

  return useMutation((params) => getFloor(params), {
    onSuccess: ({ data }, payload) => {
      if (data.length === 0) {
        openModal(
          "상영관 층계 정보가 존재하지 않습니다. 먼저 층계 정보를 생성해 주세요."
        );
        return;
      }
      const copy = tables.slice();
      const obj = {
        tabName: `층계 관리`,
        id: `floor-category/${payload.theaterId}`,
        table: <GetFloor data={data} />,
      };
      const mapping = tableDepsMap(copy, obj);

      if (!mapping) {
        setTables([...copy, obj]);
        return;
      } else {
        setTables(mapping);
      }
      queryClient.setQueryData(["get", `floor-category/`], data);
      return data;
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useUpdateFloor() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateFloor(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", `floor-category/`]);
      queryClient.invalidateQueries(["get", `floor-category/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteFloor() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteFloor(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", `floor-category/`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

//상영관 좌석 정보
export function useCreateSeat() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createTheaterInfo(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "theater-info/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useGetSeat() {
  return useQuery({
    queryKey: ["get", `theater-info/`],
    queryFn: async () => {
      const { data } = await getTheaterInfo();
      return data;
    },
    onError: (e) => {
      console.log("e", e);
    },
  });
}

export function useUpdateSeat() {
  const queryClient = useQueryClient();

  return useMutation((req) => updateTheaterInfo(req), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(["get", "theater-info/"]);
      queryClient.invalidateQueries(["get", `theater-info/${id}`]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}

export function useDeleteSeat() {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteTheaterInfo(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["get", "theater-info/"]);
    },
    onError: (e) => {
      onErrorBind(e);
    },
  });
}
