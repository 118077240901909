import { Button, Paper, TextField } from "@mui/material";
import TinyEditor from "../../../../../utils/TinyEditor";
import MuiSwitch from "../../../../common/MuiSwitch";
import {
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useRef, useState, useCallback, useEffect } from "react";
import { useCreateNotice } from "../../../../../query/queries/calliope/customerService";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";

export default function PostNotice() {
  const { openModal } = useModalStore();
  const { mutateAsync } = useCreateNotice();
  const editorRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const { setIsGuard } = useLeavingGuardStore();

  const addNotice = useCallback(
    (values) => {
      const payload = { ...values, flag };
      mutateAsync(payload);
    },
    [flag]
  );

  return (
    <div id="postNotice">
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: "",
          content: "",
        }}
        validationSchema={() => Schema.notice()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          openModal(`공지를 추가 하시겠습니까?`, () => addNotice(values));
        }}
      >
        {({ errors, touched }) => (
          <Form onChange={() => setIsGuard(true)}>
            <Paper sx={{ p: 1 }}>
              <div className="rel">
                <Field
                  name="title"
                  type="text"
                  className="formik-input"
                  id={errors.title && touched.title && "error-input-border"}
                >
                  {({ field, form, meta }) => (
                    <>
                      <TextField
                        {...field}
                        required
                        sx={{ mb: 1.6 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={"TITLE"}
                        placeholder="제목을 입력해 주세요."
                        error={meta.error && meta.touched}
                        helperText={meta.error && meta.touched && meta.error}
                      />
                    </>
                  )}
                </Field>
              </div>
              <MuiSwitch checked={flag} setChecked={setFlag} />
              <TinyEditor name="content" editorRef={editorRef} />
            </Paper>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              공지 등록
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
