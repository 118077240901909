import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateField } from "@mui/x-date-pickers";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function MuiDateField({ name, setFieldValue, fieldValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={["DateField"]}
        sx={{ pt: 0, overflow: "unset", maxWidth: 150 }}
      >
        <DateField
          format="YYYY-MM-DD"
          value={dayjs(fieldValue)}
          label={name}
          onChange={(date) => {
            if (!date) {
              setFieldValue(name, "", true);
            } else {
              const value = date.format("YYYY-MM-DD");
              setFieldValue(name, value, true);
            }
          }}
          clearable
          InputLabelProps={{ shrink: true }}
          slots={{ clearIcon: HighlightOffIcon }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
