import { Button, Paper, TextField } from "@mui/material";
import {
  useExportStore,
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../../store/creates";
import { useState, useEffect, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../../utils/validate/Schema";
import { useUpdateFloor } from "../../../../../../query/queries/orfeo/venueManage";
import CodeSample from "../../../../../../utils/CodeSample";

const updateFields = [
  {
    name: "id",
    type: "number",
    disabled: true,
    required: true,
  },
  {
    name: "name",
    type: "text",
    placeholder: "공연장 층계 정보 이름입니다. (1층, 2층)",
    required: true,
  },
  {
    name: "idx",
    type: "number",
    placeholder: "층계 순서 입니다.",
    required: true,
  },
];

export default function UpdateFloor() {
  const endpoint = "floor-category";
  const { openModal } = useModalStore();
  const { setIsGuard } = useLeavingGuardStore();
  const { mutateAsync } = useUpdateFloor();
  const { exportData } = useExportStore();
  const importData = exportData[endpoint];
  const [isImport, setIsImport] = useState(false);

  const getExportData = () => {
    if (!exportData[endpoint]) {
      openModal(-1); //export 없는 경우
      return;
    }
    setIsImport(!isImport);
  };

  const updateFloor = useCallback((values) => {
    const payload = { ...values };
    openModal(`층계 정보를 수정 하시겠습니까?`, () => mutateAsync(payload));
  });

  useEffect(() => {
    if (isImport) {
      setIsGuard(true);
    }
  }, [isImport]);

  return (
    <div id="updateFloor">
      <Button variant="contained" color="primary" onClick={getExportData}>
        {!isImport ? "IMPORT" : "CLEAR"}
      </Button>
      {importData && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: importData.id,
            theaterId: importData.data.theaterId,
            name: importData.data.name,
            idx: importData.data.idx,
          }}
          validationSchema={() => Schema.floor(true)}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            updateFloor(values);
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              {isImport && (
                <>
                  <CodeSample sample={values} />
                  <Paper sx={{ p: 1, mt: 1 }}>
                    {updateFields.map((f) => (
                      <div className="rel" key={f.name}>
                        <Field
                          name={f.name}
                          type={f.type}
                          className="formik-input"
                          id={
                            errors[f.name] &&
                            touched[f.name] &&
                            "error-input-border"
                          }
                        >
                          {({ field, meta }) => (
                            <>
                              <TextField
                                {...field}
                                required={f.required}
                                sx={{ mb: 1.6, minWidth: 150 }}
                                label={f.name}
                                placeholder={f.placeholder}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={f.disabled}
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    ))}
                  </Paper>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 1 }}
                    type="submit"
                  >
                    층계 수정
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
