import * as Yup from "yup";

//자주 사용하는 id 확인 스키마
const idSchema = {
  id: Yup.number()
    .required("필수 항목입니다.")
    .typeError("숫자를 입력해 주세요."),
};
//자주 사용하는 pw 확인 스키마
const pwSchema = {
  password: Yup.string()
    .matches(
      /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/,
      "비밀번호는 문자 숫자 특수문자의 조합으로 8~20자로 입력해주세요."
    )
    .required("비밀번호를 입력해주세요"),
};

//각종 유효성 검증을 위한 스키마 클래스
export default class Schema {
  //올레아 로그인 시
  static olea_Signin() {
    return Yup.object().shape({
      number: Yup.string().required("필수 항목입니다."),
      ...pwSchema,
    });
  }
  //오르페오 로그인 시
  static orfeo_Signin() {
    return Yup.object().shape({
      userId: Yup.string().required("필수 항목입니다."),
      ...pwSchema,
    });
  }
  //좌석 관리에서 theater-info 조회 시
  static seat() {
    return Yup.object().shape({
      theaterId: idSchema.id,
    });
  }

  //*************isId가 true면 수정 시 ID도 같이 보냄*************

  //칼리오페 공지사항 생성 및 수정 시
  static notice(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      title: Yup.string()
        .max(50, "50자 이하 입력")
        .required("필수 항목입니다."),
      content: Yup.string()
        .max(5000, "5000자 이하 입력")
        .required("필수 항목입니다."),
    };
    return Yup.object().shape(result);
  }

  //오르페오 공지 생성 및 수정 시
  static announcements(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      writer: Yup.string()
        .max(10, "10자 이하 입력")
        .required("필수 항목입니다."),
      title: Yup.string()
        .max(50, "50자 이하 입력")
        .required("필수 항목입니다."),
      content: Yup.string()
        .max(5000, "5000자 이하 입력")
        .required("필수 항목입니다."),
    };
    return Yup.object().shape(result);
  }

  //오르페오 이벤트 생성 및 수정 시
  static events(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      writer: Yup.string()
        .max(10, "10자 이하 입력")
        .required("필수 항목입니다."),
      title: Yup.string()
        .max(50, "50자 이하 입력")
        .required("필수 항목입니다."),
      content: Yup.string()
        .max(5000, "5000자 이하 입력")
        .required("필수 항목입니다."),
    };
    return Yup.object().shape(result);
  }

  //오르페오 FAQ 생성 및 수정 시
  static faq(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      category: Yup.string()
        .max(20, "20자 이하 입력")
        .required("필수 항목입니다."),
      writer: Yup.string()
        .max(10, "10자 이하 입력")
        .required("필수 항목입니다."),
      title: Yup.string()
        .max(50, "50자 이하 입력")
        .required("필수 항목입니다."),
      content: Yup.string()
        .max(5000, "5000자 이하 입력")
        .required("필수 항목입니다."),
    };
    return Yup.object().shape(result);
  }

  //오르페오 공연 생성 및 수정 시
  static products(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      title: Yup.string()
        .max(50, "50자 이하 입력")
        .required("필수 항목입니다."),
      category: Yup.string()
        .max(20, "20자 이하 입력")
        .required("필수 항목입니다."),
      posterImagePath: Yup.string()
        .max(100, "100자 이하 입력")
        .required("필수 항목입니다."),
      intermission: Yup.string()
        .max(20, "20자 이하 입력")
        .required("필수 항목입니다."),
      venueId: Yup.number()
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
      theaterId: Yup.number()
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
      firstPerformance: Yup.string()
        .max(30, "30자 이하 입력")
        .required("필수 항목입니다."),
      lastPerformance: Yup.string()
        .max(30, "30자 이하 입력")
        .required("필수 항목입니다."),
      saleStartAt: Yup.string()
        .max(30, "30자 이하 입력")
        .required("필수 항목입니다."),
      saleEndAt: Yup.string()
        .max(30, "30자 이하 입력")
        .required("필수 항목입니다."),
      status: Yup.string()
        .max(10, "10자 이하 입력")
        .required("필수 항목입니다."),
      runningTime: Yup.string()
        .max(100, "100자 이하 입력")
        .required("필수 항목입니다."),
      applyEndAt: Yup.string()
        .max(30, "30자 이하 입력")
        .required("필수 항목입니다."),
      winnerAnnouncementDate: Yup.string()
        .max(30, "30자 이하 입력")
        .required("필수 항목입니다."),
      ageLimit: Yup.string()
        .max(10, "10자 이하 입력")
        .required("필수 항목입니다."),
      discountRate: Yup.number()
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
      detailImagePath: Yup.string()
        .max(100, "100자 이하 입력")
        .required("필수 항목입니다."),
    };
    return Yup.object().shape(result);
  }

  //오르페오 극장 생성 및 수정 시
  static venue(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      name: Yup.string().max(20, "20자 이하 입력").required("필수 항목입니다."),
      location: Yup.string()
        .max(50, "50자 이하 입력")
        .required("필수 항목입니다."),
      address: Yup.string()
        .max(50, "50자 이하 입력")
        .required("필수 항목입니다."),
    };
    return Yup.object().shape(result);
  }

  //오르페오 상영관 생성 및 수정 시
  static theater(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      name: Yup.string().max(20, "20자 이하 입력").required("필수 항목입니다."),
      venueId: Yup.number()
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
      x: Yup.number()
        .min(1, "1 이상 입력")
        .max(50, "50 이하 입력")
        .required("x 필드는 필수 항목입니다."),
      y: Yup.number()
        .min(1, "1 이상 입력")
        .max(50, "50 이하 입력")
        .required("y 필드는 필수 항목입니다."),
    };
    return Yup.object().shape(result);
  }

  //오르페오 공연 회차 정보 조회 전 PAYLOAD 설정
  static productRoundBefore() {
    const result = {
      productId: Yup.number()
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
      selectedDate: Yup.string().max(30, "30자 이하 입력"), //필수 값 아니며 입력하지 않을 경우엔 해당 공연의 모든 회차 정보 조회 포맷 YYYY-MM-DD
    };
    return Yup.object().shape(result);
  }

  //오르페오 공연 회차 정보 생성 및 수정
  static productRound(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      name: Yup.string().max(20, "20자 이하 입력").required("필수 항목입니다."),
      screenDatetime: Yup.string()
        .max(30, "30자 이하 입력")
        .required("필수 항목입니다."),
      productId: Yup.number()
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
    };
    return Yup.object().shape(result);
  }

  //상영관 층계 정보 생성 및 수정
  static floor(isId = false) {
    let result = {};
    if (isId) {
      result = { ...idSchema };
    }
    result = {
      ...result,
      name: Yup.string().max(20, "20자 이하 입력").required("필수 항목입니다."),
      idx: Yup.number()
        .min(0, "0 이상 입력")
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
      theaterId: Yup.number()
        .required("필수 항목입니다.")
        .typeError("숫자를 입력해 주세요."),
    };
    return Yup.object().shape(result);
  }
}
