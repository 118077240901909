import { Button, Paper, TextField } from "@mui/material";
import {
  useExportStore,
  useLeavingGuardStore,
  useModalStore,
} from "../../../../../store/creates";
import { useState, useEffect, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import Schema from "../../../../../utils/validate/Schema";
import { useUpdateProductRound } from "../../../../../query/queries/orfeo/productsManage";
import CodeSample from "../../../../../utils/CodeSample";
import { updateProductRound } from "../../../../../query/apis/orfeo/UPDATE";
import MuiDateTimeField from "../../../../common/MuiDateTimeField";

const updateFields = [
  {
    name: "id",
    type: "number",
    disabled: true,
    required: true,
  },
  {
    name: "name",
    type: "text",
    placeholder: "회차 제목을 입력해 주세요.",
    required: true,
  },
  {
    name: "screenDatetime",
    type: "text",
    placeholder: "회차 시간을 입력해 주세요.",
    required: true,
    dateTime: true,
  },
];

export default function UpdateProductRound() {
  const endpoint = "product-round";
  const { openModal } = useModalStore();
  const { setIsGuard } = useLeavingGuardStore();
  const { mutateAsync } = useUpdateProductRound();
  const { exportData } = useExportStore();
  const importData = exportData[endpoint];
  const [isImport, setIsImport] = useState(false);

  const getExportData = () => {
    if (!exportData[endpoint]) {
      openModal(-1); //export 없는 경우
      return;
    }
    setIsImport(!isImport);
  };

  const updateProductRound = useCallback((values) => {
    const payload = { ...values };
    openModal(`공연 회차 정보를 수정 하시겠습니까?`, () =>
      mutateAsync(payload)
    );
  });

  useEffect(() => {
    if (isImport) {
      setIsGuard(true);
    }
  }, [isImport]);

  return (
    <div id="updateProducts">
      <Button variant="contained" color="primary" onClick={getExportData}>
        {!isImport ? "IMPORT" : "CLEAR"}
      </Button>
      {importData && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: importData.id,
            name: importData.data.name,
            screenDatetime: importData.data.screenDatetime,
            productId: importData.data.productId,
          }}
          validationSchema={() => Schema.productRound(true)}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            updateProductRound(values);
          }}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              {isImport && (
                <>
                  <CodeSample sample={values} />
                  <Paper sx={{ p: 1, mt: 1 }}>
                    {updateFields.map((f) => (
                      <div className="rel" key={f.name}>
                        {f.dateTime ? (
                          <MuiDateTimeField
                            fieldValue={values.screenDatetime}
                            name={"screenDatetime"}
                            setFieldValue={setFieldValue}
                          />
                        ) : (
                          <Field
                            name={f.name}
                            type={f.type}
                            className="formik-input"
                            id={
                              errors[f.name] &&
                              touched[f.name] &&
                              "error-input-border"
                            }
                          >
                            {({ field, meta }) => (
                              <>
                                <TextField
                                  {...field}
                                  required={f.required}
                                  sx={{ mb: 1.6, minWidth: 150 }}
                                  label={f.name}
                                  placeholder={f.placeholder}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={f.disabled}
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched && meta.error
                                  }
                                />
                              </>
                            )}
                          </Field>
                        )}
                      </div>
                    ))}
                  </Paper>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 1 }}
                    type="submit"
                  >
                    공연 회차 정보 수정
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
