import { useNavigate } from "react-router-dom";
import { useModalStore, useTokenStore } from "../../../store/creates";
import { signIn } from "../../apis/orfeo/POST";
import { onErrorBind } from "../common";
import { useMutation } from "@tanstack/react-query";
import TokenService from "../../../service/token";
import requests from "../../request";

export function useSignIn() {
  const { setToken } = useTokenStore();
  const { openModal } = useModalStore();

  return useMutation((payload) => signIn(payload), {
    onSuccess: (res) => {
      const { accessToken, refreshToken } = res.data;
      TokenService.save("orfeo", accessToken, refreshToken);
      //로그인시 쿠키에 저장
      setToken({
        orfeoAccessToken: accessToken,
        orfeoRefreshToken: refreshToken,
      }); //로그인시 스토어에 토큰 저장
      requests.orfeo.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
    },
    onError: (e) => {
      const message = e.response.data.message;
      openModal(message, false);
      onErrorBind(e);
    },
  });
}
