import { Button, Paper, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import { useModalStore } from "../../../../../../store/creates";
import Schema from "../../../../../../utils/validate/Schema";
import CodeSample from "../../../../../../utils/CodeSample";
import {
  useCreateFloor,
  useGetTheater,
} from "../../../../../../query/queries/orfeo/venueManage";

export default function PostFloor() {
  const { mutateAsync } = useCreateFloor();
  const { openModal } = useModalStore();
  const { data: theaterData } = useGetTheater();
  const getProductRound = useCallback((values) => {
    mutateAsync(values);
  }, []);

  const fields = useMemo(
    () => [
      {
        name: "theaterId",
        type: "text",
        select: true,
        options: theaterData || [],
        required: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "공연장 층계 정보 이름입니다. (1층, 2층)",
        required: true,
      },
      {
        name: "idx",
        type: "number",
        placeholder: "층계 순서 입니다.",
        required: true,
      },
    ],
    [theaterData]
  );

  return (
    <div id="getProductRound">
      <Formik
        enableReinitialize={true}
        initialValues={{
          theaterId: "",
          name: "",
          idx: "",
        }}
        validationSchema={() => Schema.floor()}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          let message = "입력한 데이터로 층계 정보를 생성하시겠습니까?";

          openModal(message, () => getProductRound(values));
        }}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form>
            <CodeSample sample={values} />
            <Paper sx={{ p: 1 }}>
              {fields.map((f) => (
                <div className="rel" key={f.name}>
                  <Field
                    name={f.name}
                    type={f.type}
                    className="formik-input"
                    id={
                      errors[f.name] && touched[f.name] && "error-input-border"
                    }
                  >
                    {({ field, meta }) => (
                      <>
                        <TextField
                          {...field}
                          required={f.required}
                          type={f.type}
                          select={f.select}
                          sx={{ mb: 1.6, minWidth: 150 }}
                          label={f.name}
                          disabled={f.disabled}
                          placeholder={f.placeholder}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={meta.error && meta.touched}
                          helperText={meta.error && meta.touched && meta.error}
                        >
                          {f.options?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              [상영관 ID: {option.id}] {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                  </Field>
                </div>
              ))}
            </Paper>

            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              type="submit"
            >
              층계 정보 등록
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
