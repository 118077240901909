import { useLocation } from "react-router-dom";
import { useFavoriteStore } from "../../store/creates";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import IconButton from "@mui/material/IconButton";
import StarRateIcon from "@mui/icons-material/StarRate";

export const useFavorite = (label) => {
  const { setFavorite, favorite } = useFavoriteStore();
  const { pathname } = useLocation();
  const find = favorite.find((f) => f.path === pathname);

  const IconBtn = () => {
    return (
      <IconButton
        aria-label="favorite"
        color="primary"
        onClick={(e) => {
          let result;
          if (find) {
            result = favorite.filter((f) => f.path !== pathname);
          } else {
            result = [
              ...favorite,
              {
                path: pathname,
                text: label,
              },
            ];
          }
          setFavorite(result);
        }}
      >
        {find ? <StarRateIcon /> : <StarBorderIcon />}
      </IconButton>
    );
  };

  return { IconBtn };
};
