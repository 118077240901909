import React, { useEffect, useMemo, useState } from "react";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StoreIcon from "@mui/icons-material/Store";
import CategoryIcon from "@mui/icons-material/Category";
import MovieIcon from "@mui/icons-material/Movie";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NotFound from "./NotFound";
import Home from "./Home";
import DevTool from "../tools/DevTool";
import SigninManage from "./SigninManage";
import oleaIcon from "../../imgs/OLEA/olea_favicon.ico";
import orfeoIcon from "../../imgs/ORFEO/ORFEO_cultures.png";
import calliopeIcon from "../../imgs/CALLIOPE/calliope_logo.png";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import TheatersIcon from "@mui/icons-material/Theaters";
import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import swaggerLogo from "../../imgs/Swagger-logo.png";
import ConfirmModal from "../../utils/ConfirmModal";
import OleaUserManage from "../menu/olea/userManage/UserManage";
import OrfoUserManage from "../menu/orfeo/userManage/UserManage";
import MarketManage from "../menu/olea/marketManage/MarketManage";
import GoodsManage from "../menu/olea/goodsManage/GoodsManage";
import {
  useFavoriteStore,
  useLeavingGuardStore,
  useModalStore,
  useRouteFollowerStore,
  useTokenStore,
} from "../../store/creates";
import CalliopeCustomerService from "../menu/calliope/CustomerService/CustomerService";
import OrfeoCustomerService from "../menu/orfeo/customerService/CustomerService";
import ProductsManage from "../menu/orfeo/productsManage/ProductsManage";
import VenueManage from "../menu/orfeo/venueManage/VenueManage";

const drawerWidth = 280;

const MuiMain = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    marginLeft: 0,
    overflow: "hidden",
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

let idx = 0;

//올레아 메뉴 라우팅
const oleaMenus = [
  {
    id: idx++,
    path: "/olea/users",
    text: "사용자 관리",
    Component: <OleaUserManage />,
    icon: <AssignmentIndIcon />,
  },
  {
    id: idx++,
    path: "/olea/market",
    text: "마켓 관리",
    Component: <MarketManage />,
    icon: <StoreIcon />,
  },
  {
    id: idx++,
    path: "/olea/goods",
    text: "상품 관리",
    Component: <GoodsManage />,
    icon: <CategoryIcon />,
  },
];

//오르페오 메뉴 라우팅
const orfeoMenus = [
  {
    id: idx++,
    path: "/orfeo/users",
    text: "사용자 관리",
    Component: <OrfoUserManage />,
    icon: <AssignmentIndIcon />,
  },
  {
    id: idx++,
    path: "/orfeo/venue",
    text: "극장 관리",
    Component: <VenueManage />,
    icon: <TheatersIcon />,
  },
  {
    id: idx++,
    path: "/orfeo/products",
    text: "공연 관리",
    Component: <ProductsManage />,
    icon: <MovieIcon />,
  },
  {
    id: idx++,
    path: "/orfeo/customerService",
    text: "고객센터",
    Component: <OrfeoCustomerService />,
    icon: <SupportAgentIcon />,
  },
];

//칼리오페 메뉴 라우팅
const calliopeMenus = [
  {
    id: idx++,
    path: "/calliope/customerService",
    text: "고객센터",
    Component: <CalliopeCustomerService />,
    icon: <SupportAgentIcon />,
  },
];

//로그인 메뉴 라우팅
const signinMenus = [
  {
    id: idx++,
    path: "/signin",
    text: "로그인 & 로그아웃",
    Component: <SigninManage />,
    icon: (
      <>
        <LoginIcon />
        <LogoutIcon />
      </>
    ),
  },
];

//전체 컴포넌트의 가장 부모 컴포넌트로 다른 컴포넌트를 렌더하는 구조를 가지고 있음 (App 제외)
export default function Main() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true); //좌측 네비 토글 여부
  const [openSub, setOpenSub] = useState([true, true, true]); //메뉴 갯수 만큼 필요 (OLEA, ORFEO, CALLIOPE 관리) 추후 하드코딩 귀찮을 시 메뉴 갯수에 따라 동적으로 생성하게 수정 가능
  const [favoriteOpenSub, setFavoriteOpenSub] = useState(false); //즐겨찾기 메뉴 오픈 여부
  const { token } = useTokenStore(); //로그인 토큰 가져오기
  const { openModal } = useModalStore();
  const { isGuard, setIsGuard } = useLeavingGuardStore(); //데이터 입력 후 라우팅 이동 막는 용도의 상태 (Create, Update를 하는 대부분의 컴포넌트에서 데이터 입력 시 isGuard true)
  const { setFollower } = useRouteFollowerStore(); //현재 메뉴 추적 용도 Set 함수 (페이지 타입에 따른 api 분리 처리를 위해 사용중)
  const { pathname } = useLocation();
  const { favorite } = useFavoriteStore(); //즐겨찾기 정보

  //Drawer 전체 메뉴 (배열에 순서대로 Drawer에 렌더)
  const categories = useMemo(
    () => [
      {
        name: "로그인 관리",
        iconComponent: <LockOpenIcon />,
        menus: signinMenus, //위에서 정의한 로그인 메뉴 정보
        access: true, //true로 평가 되면 메뉴에 상시 렌더
      },
      {
        name: "OLEA 관리",
        icon: oleaIcon,
        menus: oleaMenus, //위에서 정의한 올레아 메뉴 정보
        access: token.oleaAccessToken, //올레아 access 토큰을 읽을 수 있을 경우 렌더
      },
      {
        name: "ORFEO 관리",
        icon: orfeoIcon,
        menus: orfeoMenus, //위에서 정의한 오르페오 메뉴 정보
        access: token.orfeoAccessToken, //오르페오 access 토큰을 읽을 수 있을 경우 렌더
      },
      {
        name: "CALLIOPE 관리",
        icon: calliopeIcon,
        menus: calliopeMenus, //위에서 정의한 칼리오페 메뉴 정보
        access: token.oleaAccessToken, //칼리오페 access 토큰을 읽을 수 있을 경우 렌더 (하지만 현재 오르페오 로그인 분리 안되어있어 olea로 사용 중)
      },
    ],
    [token]
  );

  //Drawer 접기 펴기
  const handleDrawer = () => {
    setOpen(!open);
  };

  //Drawer > menu 접기 펴기
  const handleSubDrawer = (idx) => {
    const copy = openSub.slice();
    copy[idx] = !copy[idx];
    setOpenSub(copy);
  };

  //현재 페이지 정보에 따라 페이지타입을 저장
  useEffect(() => {
    const regex = /\/(.*?)\//;
    const match = pathname.match(regex); //현재 pathname에서 olea 혹은 orfeo 혹은 calliope 를 추출,

    if (match) {
      const extractedContent = match[1]; //추출한 값은 match[1]에 존재하며 해당 추출한 데이터를 pageType으로 사용
      setFollower(extractedContent);
    }
  }, [pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <ConfirmModal /> {/* 모달 컴포넌트*/}
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* 개발자용 스웨거 링크 */}
          <div className="flex-tween logo">
            <div className="rel">
              <Typography
                variant="h6"
                style={{ cursor: "pointer" }}
                noWrap
                onClick={() => {
                  navigate("/");
                }}
              >
                BLOCKCHAIN
              </Typography>
              <figure className="flex-center">
                <img
                  src={swaggerLogo}
                  className="ico30"
                  onClick={() => {
                    window.open(
                      "https://admin-api-dev.oleamarket.com/api-docs/",
                      "",
                      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
                    );
                  }}
                />
                <figcaption className="ml8">OLEA</figcaption>
              </figure>
              <figure className="flex-center">
                <img
                  src={swaggerLogo}
                  className="ico30"
                  onClick={() => {
                    window.open(
                      "https://orfeo-back.oleamarket.com/api-docs/",
                      "",
                      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
                    );
                  }}
                />
                <figcaption className="ml8">ORFEO</figcaption>
              </figure>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {/* 즐겨찾기는 별도의 라우팅을 따로 가지지 않기 때문에 별도로 반드시 존재하는 메뉴 */}
        <List>
          <ListItem button onClick={() => setFavoriteOpenSub(!favoriteOpenSub)}>
            <ListItemIcon>
              <StarBorderIcon />
            </ListItemIcon>
            <ListItemText primary={"즐겨찾기"} />
            {favoriteOpenSub ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={favoriteOpenSub} timeout="auto" unmountOnExit>
            {[
              { name: "OLEA", icon: oleaIcon },
              { name: "ORFEO", icon: orfeoIcon },
              { name: "CALLIOPE", icon: calliopeIcon },
            ].map((data) => (
              <React.Fragment key={data.name}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <img src={data.icon} style={{ width: 30 }} />
                    </ListItemIcon>
                    <ListItemText primary={data.name} />
                  </ListItem>
                </List>
                <div>
                  <List component="div">
                    {favorite
                      .filter((f) =>
                        f.path.includes(`/${data.name.toLowerCase()}/`)
                      )
                      .map((f) => (
                        <Link
                          to={f.path}
                          key={f.path}
                          onClick={(e) => {
                            if (isGuard && f.path !== pathname) {
                              e.preventDefault();
                              openModal(
                                "입력한 데이터가 사라져도 괜찮습니까?",
                                () => {
                                  setIsGuard(false);
                                  navigate(f.path);
                                }
                              );
                            }
                          }}
                        >
                          <ListItem>
                            <ListItemIcon>
                              <StarBorderIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.text} />
                          </ListItem>
                        </Link>
                      ))}
                  </List>
                </div>
              </React.Fragment>
            ))}
          </Collapse>
        </List>
        <Divider />
        {/* 현재 카티고리에 담긴 데이터를 메뉴로 렌더 */}
        {categories.map((category, idx) => (
          <React.Fragment key={category.name}>
            {category.access && ( //access 평가가 true일 때 렌더
              <>
                <List>
                  {/* 메뉴 접기 펴기 */}
                  <ListItem button onClick={() => handleSubDrawer(idx)}>
                    {/* iconComponent일 경우 데이터는 컴포넌트로 렌더 icon으로 존재하면 img src로 렌더 */}
                    <ListItemIcon>
                      {category.iconComponent ? (
                        category.iconComponent
                      ) : (
                        <img
                          src={category.icon}
                          alt={category.name}
                          style={{ width: 40 }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={category.name} />
                    {/* 접힘 여부에 따른 화살표 아이콘 반전 */}
                    {openSub[idx] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {/* 펼치면 렌더되는 라우팅 앵커 */}
                  <Collapse in={openSub[idx]} timeout="auto" unmountOnExit>
                    <List component="div">
                      {category.menus.map((data) => (
                        <Link
                          to={data.path}
                          key={data.id}
                          onClick={(e) => {
                            //isGuard가 true일경우 페이지 이동 여부를 컨펌받음 단 현재 페이지로 이동하려고하면 묻지 않으며 아무런 반응 없음
                            if (isGuard && data.path !== pathname) {
                              e.preventDefault();
                              openModal(
                                "입력한 데이터가 사라져도 괜찮습니까?",
                                () => {
                                  setIsGuard(false);
                                  navigate(data.path);
                                }
                              );
                            }
                          }}
                        >
                          <ListItem>
                            <ListItemIcon>{data.icon}</ListItemIcon>
                            <ListItemText primary={data.text} />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                </List>
                <Divider />
              </>
            )}
          </React.Fragment>
        ))}
      </Drawer>
      <MuiMain open={open}>
        <DrawerHeader />
        {/* 라우팅 처리 */}
        <Routes>
          {signinMenus.map((data) => (
            <Route key={data.id} path={data.path} element={data.Component} />
          ))}
          {oleaMenus.map((data) => (
            <Route key={data.id} path={data.path} element={data.Component} />
          ))}
          {orfeoMenus.map((data) => (
            <Route key={data.id} path={data.path} element={data.Component} />
          ))}
          {calliopeMenus.map((data) => (
            <Route key={data.id} path={data.path} element={data.Component} />
          ))}
          <Route path="/" element={<Home />} />
          <Route element={<NotFound />} />
        </Routes>
      </MuiMain>
      {/* 개발 도구 DevTool */}
      <DevTool />
    </Box>
  );
}
