export default function mergeIdMap(uniKey, array) {
  const result = [];

  array.forEach((obj) => {
    if (obj.id) {
      const newObj = { ...obj };
      delete newObj.id;
      result.push({ [uniKey]: obj.id, ...newObj });
    } else if (obj[uniKey]) {
      result.push({ ...obj });
    }
  });

  return result;
}

export function mergeId(uniKey, data) {
  let result = {};
  if (data.id) {
    const newObj = { ...data };
    delete newObj.id;
    result = { [uniKey]: data.id, ...newObj };
  } else if (data[uniKey]) {
    result = { ...data };
  }

  return result;
}

export function tableDepsMap(data, newData) {
  let isChanged = false;
  const result = data.map((item) => {
    if (item.id === newData.id) {
      isChanged = true;
      return newData; // 새로운 데이터로 교체
    }
    return item; // 원래 데이터 유지
  });
  return isChanged && result; //변경 사항이 없을 경우 false 반환
}
